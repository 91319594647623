import React from "react";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const handleContact = () => {
        navigate("/contact-us");
    };

    return (
        <>
            <div className="container-fluid" style={{ overflow: "hidden" }}>
                <div className="row shop-banner-row">
                    <div className="col-md-6 justify-content-center banner-content-contact px-5">
                        <h5 className="banner-header-returns" style={{ fontWeight: "700", fontStyle: "italic", fontSize: "48px", color: "#fff" }}>
                            Privacy Policy
                        </h5>
                        <p style={{ fontSize: "22px", color: "#fff" }}>A privacy policy builds trust by detailing how personal data is collected, used, and protected.</p>
                        <button className="explore-button-shop" onClick={handleContact}>Contact Us</button>
                    </div>
                </div>
            </div>

            <div className="container mt-3 mb-3" style={{ padding: "30px", textAlign: 'left', backgroundColor: "#f8f9fa", borderRadius: "10px" }}>
                <p>At Builderneed, we take your privacy seriously and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and safeguard your information when you interact with our website or app.</p>

                <h5>What Personal Information We Collect:</h5>
                <p>When you visit or use our website/app, we may collect the following types of personal information:</p>
                <ul>
                    <li><strong>Contact Information:</strong> Name, email address, phone number, and shipping address.</li>
                    <li><strong>Account Information:</strong> Username, password, and order history.</li>
                    <li><strong>Payment Information:</strong> Credit card or debit card details, billing address (processed securely through third-party payment gateways).</li>
                    <li><strong>Usage Data:</strong> IP address, browser type, device information, and browsing activity on our website/app.</li>
                    <li><strong>Feedback:</strong> Any product reviews, comments, or survey responses you provide.</li>
                </ul>

                <h5>How We Collect the Information:</h5>
                <p>We collect personal information in the following ways:</p>
                <ul>
                    <li><strong>Direct Input:</strong> Information you provide when registering for an account, placing an order, or contacting us through forms or email.</li>
                    <li><strong>Cookies and Tracking:</strong> We use cookies and other tracking technologies to collect information about your usage of our website/app, such as browsing habits and preferences.</li>
                    <li><strong>Third-Party Services:</strong> Information collected from payment processors, analytics tools, and advertising platforms you interact with through our site.</li>
                </ul>

                <h5>How We Use the Collected Information:</h5>
                <p>We use the personal information we collect for various purposes, including:</p>
                <ul>
                    <li><strong>Order Processing:</strong> To process and fulfill your orders, including shipping and communication about your order status.</li>
                    <li><strong>Customer Support:</strong> To assist you with any inquiries, concerns, or technical issues you may encounter.</li>
                    <li><strong>Personalization:</strong> To tailor your experience on our website/app by displaying relevant products and offers.</li>
                    <li><strong>Marketing & Promotions:</strong> To send you promotional emails, special offers, and updates, only if you have opted in.</li>
                    <li><strong>Analytics:</strong> To understand user behavior and improve our website/app performance and offerings.</li>
                </ul>

                <h5>How We Keep Information Safe:</h5>
                <p>We implement a variety of security measures to ensure your personal information is protected:</p>
                <ul>
                    <li><strong>Data Encryption:</strong> We use secure socket layer (SSL) technology to encrypt personal and payment information during transmission.</li>
                    <li><strong>Access Control:</strong> Only authorized personnel have access to personal information, and all staff are trained in data security practices.</li>
                    <li><strong>Third-Party Services:</strong> We work with trusted third-party payment processors and ensure they comply with industry standards for data protection.</li>
                </ul>

                <h5>Information Sharing with Third Parties:</h5>
                <p>We respect your privacy and only share personal information with third parties under the following circumstances:</p>
                <ul>
                    <li><strong>Service Providers:</strong> We may share your information with third-party service providers (e.g., shipping companies, payment processors) to complete transactions and deliver products.</li>
                    <li><strong>Legal Compliance:</strong> We may disclose personal information if required by law or in response to a legal request (e.g., court orders, government inquiries).</li>
                    <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your personal information may be transferred to the new entity.</li>
                </ul>
                <p>We do not sell, rent, or trade your personal information to third parties for their marketing purposes.</p>

                <h5>Contact Us:</h5>
                <p>If you have any questions or concerns regarding our Privacy Policy, please contact us at <a href="mailto:support@skcements.com">support@skcements.com</a>. We are committed to ensuring your privacy is protected at all times.</p>
            </div>
        </>
    );
};

export default PrivacyPolicy;

import React, { useEffect, useState } from "react";
import "./Footer.css";
import footer from "../../Assets/SKImages/footer-bg.png";
import googleImg from "../../Assets/icons/Google Play.png";
import appImg from "../../Assets/icons/App Store.png";
import { GrLocation } from "react-icons/gr";
import { FiPhone } from "react-icons/fi";
import { TiSocialLinkedin } from "react-icons/ti";
import { FaFacebookF } from "react-icons/fa";
import { SlSocialLinkedin } from "react-icons/sl";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';


import { setAuthState } from "../../states/reducers/AuthSlice";



import {
  MailOutlined,
  FacebookFilled,
  InstagramOutlined,
  XOutlined,
  LinkedinOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import Instance from "../../AxiosConfig";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [storeInfo, setStoreInfo] = useState(null);
  const [containerClass, setContainerClass] = useState("container");
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch()
  // const handleWishlist = () => {
  //   if (isLoggedIn) {
  //     navigate("/profile/wishlist");
  //   } else {
  //     navigate("/wishlist");
  //   }
  //   toggleMenu();
  // };
  // const handleCategoryClick = (productType) => {
  //   navigate(`/shop?type=${productType}`);
  // };

  useEffect(() => {
    const userToken = localStorage.getItem("authToken");
    dispatch(setAuthState({ isLoggedIn: !!userToken, userData: null }));
  }, [dispatch]);

  const wishlistsData = useSelector((state) => state.wishlist.wishlistsData);
  // const favoritesCount = wishlistsData.length;

  const CartData = useSelector((state) => state.cart.AddToCartData);
  // const cartCount = CartData.cartItems.length;
  // const totalDiscountedPrice = CartData.totalDiscountedPrice;


  const fetchWishlistData = async () => {
    try {
      const sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/wishlist/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error fetching wishlist data:", error);
    }
  };
  useEffect(() => {
    fetchWishlistData(dispatch);
  }, [dispatch]);

  const fetchCartCount = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/cart/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      console.log('API Response:', response);
      console.log('Cart Items:', response.data.cartItems);

      // dispatch(addItems(response.data.cartItems));
    } catch (error) {
      console.error("Error fetching cart count:", error);
    }
  };

  useEffect(() => {
    fetchCartCount(dispatch);
  }, [dispatch]);

  const handleClick = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
    } else {
      setError('');
      console.log('Valid email submitted:', email);
      setEmail('');
      navigate('/contact-us')
    }
  };

  useEffect(() => {
    // Function to handle resizing and update the container class
    const handleResize = () => {
      if (window.innerWidth < 1800) {
        setContainerClass("container-fluid");
      } else {
        setContainerClass("container");
      }
    };

    // Set the initial class on component mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchStoreInfo = async () => {
      try {
        const response = await Instance.get("/api/v1/store-info");
        console.log(response.data);
        setStoreInfo(response.data);
      } catch (error) {
        console.error("Error fetching store info:", error);
      }
    };

    fetchStoreInfo();
  }, []);

  const formatAddress = (address) => {
    const parts = address.split(',').map(part => part.trim());

    let formattedAddress = '';
    let count = 0;

    parts.forEach((part, index) => {
      formattedAddress += part;
      if (index < parts.length - 1) {
        formattedAddress += ', ';
      }
      if ((index + 1) % 2 === 0 && index !== parts.length - 1) {
        formattedAddress += '<br />';
      }
    });

    return formattedAddress;
  };

  return (
    <div className={`${containerClass} mx-auto footer-container`} style={{ overflow: "hidden" }}>
      <div>

        <div className="footer-row ">
          {/* <div className="media-footer"> */}
          <div className="footer-name px-4 footer-padding-tab">
            <div className="footer-column ">
              <h5>Quick Links</h5>
              <ul className="footer-about-us">
                <li>
                  <Link to="/" className="link">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/shop" className="link">
                    Shop
                  </Link>
                </li>
                {/* <li>
                  <Link to="/about" className="link">
                    About Us
                  </Link>
                </li> */}
                <li>
                  <Link to="/contact-us" className="link">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="link">
                    FAQs
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-column footer-our-products">
              <h5>Customer Service</h5>
              <ul>
                <li>
                  <Link to="/terms-and-conditions" className="link">
                    Terms and Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/cancellation-and-refund" className="link">
                    Cancellation and Refund
                  </Link>
                </li>
                <li>
                  <Link to="/shipping-info" className="link">
                    Shipping Info
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="link">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="footer-tab-row"> */}
          <div className="footer-contact-subscribe d-flex">
            <div className="footer-column footer-contact-us px-4 footer-padding-tab">
              <h5>Contact Us</h5>
              <div className="d-flex mobile-response-gap ">
                <GrLocation className="contact-icon mt-1" />
                <div className="d-flex mt-2 flex-column footer-address-text">
                  <p>383, 9th Main Rd,</p>
                  <p>7th Sector, HSR Layout,</p>
                  <p>Bengaluru, Karnataka 560102</p>
                </div>

              </div>
              <div className="d-flex mobile-response-gap ">
                <FiPhone className="contact-icon mt-1" />
                {/* <p> {storeInfo?.phoneNumber}</p> */}
                <p>+91 8660519994</p>
              </div>
              <div className="d-flex mobile-response-gap">
                <MailOutlined className="contact-icon mt-1" style={{ paddingLeft: '5px' }} />
                <p>
                  {" "}
                  <a href="mailto:support@skcements.com">support@skcements.com</a>
                </p>
              </div>
            </div>
            <div className="footer-column footer-subscribe px-4 footer-padding-tab">
              <h5>Subscribe To Our Newsletter!</h5>
              <div className="d-flex gap-0">
                <input
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" onClick={handleClick}>Submit</button>
              </div>
              {error && <p style={{ color: 'red' }} className="mb-0">{error}</p>}
              <div className="footer-social pt-4">
                <h5>Connect With Us On:</h5>
                <ul class="social-icons">
                  <li class="social-icon">
                    <a href="#" class="social-link">
                      <i class="fab fa-facebook-f icon"></i>
                    </a>
                  </li>
                  <li class="social-icon">
                    <a href="#" class="social-link"><i class="fab fa-twitter icon"></i></a>
                  </li>
                  <li class="social-icon">
                    <a href="#" class="social-link"><i class="fab fa-linkedin-in icon"></i></a>
                  </li>
                  <li class="social-icon">
                    <a href="#" class="social-link"><i class="fab fa-instagram icon bold-icon"></i></a>
                  </li>
                </ul>

              </div>
              <div>
                <ul className="d-flex gap-2 mb-4">
                  <li>
                    <img style={{ width: '120px', height: 'auto' }} src={googleImg} alt="" className="footer-google" />
                  </li>
                  <li>
                    <img style={{ width: '120px', height: 'auto' }} src={appImg} alt="" className="footer-google" />
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
        {/* </div> */}
        <div className="footer-line"></div>

        <div className="footer-bottom-row">
          <div className="col footer-copyrights-div">
            <p>Copyright © 2024 | Builderneed | All Rights Reserved.</p>
          </div>
          <div>
            <img className="footer-img-bg" src={footer} alt="../" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import InvoiceLogo1 from '../../Assets/SKImages/pdf-header-logo.png'
import Instance from '../../AxiosConfig';
import { Spin } from 'antd';
import Home from './Home';
import HomeBanner from './HomeBanner';
import Sustainability from './Sustainability';
import Clients from './Clients';
import Testimonials from './Testimonials';
import FaqGetInTouch from '../FAQ/FaqGetInTouch';
import GetApp from './GetApp';

const MyOrdersInvoice = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  // const location = useLocation();
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(false)

  const fetchOrdersData = async () => {
    setLoading(true)
    try {
      const url = `/api/v1/orders/${orderId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        console.log("Orders data fetched successfully:", response.data);

        // Find the order with the matching orderId

        setRecord(response.data);
      }
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdersData();
  }, []);

  const groupOrderItems = (orderItems) => {
    const groupedItems = {};

    (orderItems || []).forEach((item) => {
      const key = item.productId;

      if (groupedItems[key]) {
        groupedItems[key].quantity += item.quantity;
        groupedItems[key].total += item.price;
        groupedItems[key].totalDiscountedPrice += item.discountedPrice;
        if (groupedItems[key].steelProduct && groupedItems[key].total > 0) {
          const discount = ((groupedItems[key].total - groupedItems[key].totalDiscountedPrice) / groupedItems[key].total) * 100;
          groupedItems[key].discountPercentage = discount?.toFixed(2);
        }
      } else {
        groupedItems[key] = {
          ...item,
          quantity: item.quantity,
          total: item.price,
          totalDiscountedPrice: item.discountedPrice,
          discountPercentage: item.discountPercentage,
        };
        if (item.steelProduct && groupedItems[key].totalPrice > 0) {
          const discount = ((groupedItems[key].totalPrice - groupedItems[key].totalDiscountedPrice) / groupedItems[key].totalPrice) * 100;
          groupedItems[key].discountPercentage = discount;
        }
      }
    });

    return Object.values(groupedItems);
  };

  useEffect(() => {
    if (record) {
      handleExportRows(record);
    }
  }, [record]);


  const drawBorder = (doc) => {
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const borderMargin = 7;
    // Optionally set the line color or width
    doc.setLineWidth(0.3);
    doc.setDrawColor(0);
    doc.rect(borderMargin, borderMargin, pageWidth - 2 * borderMargin, pageHeight - 2 * borderMargin);
  };

  const handleExportRows = (record) => {
    setLoading(true)
    const groupedOrderItems = groupOrderItems(record.orderItems);
    console.log("pdfdata:", record);
    const doc = new jsPDF();

    // General table data
    const logoImage = new Image();
    logoImage.src = InvoiceLogo1;

    logoImage.onload = function () {
      doc.setFillColor("#fff");
      doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");
      doc.addImage(logoImage, "PNG", 15, 10, 50, 20);

      doc.setFontSize(11);
      doc.setTextColor(80);

      const baseX = 95;
      const baseY = 12;
      const lineHeight = 6;
      const labelX = 132;
      const colonX = 162;
      const valueX = 164;

      doc.text(`order ID:`, labelX, baseY);
      doc.text(`Name:`, labelX, baseY + lineHeight);
      doc.text(`Order date:`, labelX, baseY + 2 * lineHeight);
      doc.text(`Order Status:`, labelX, baseY + 3 * lineHeight);
      doc.text(`Payment Status:`, labelX, baseY + 4 * lineHeight);

      doc.text(`:`, colonX, baseY);
      doc.text(`:`, colonX, baseY + lineHeight);
      doc.text(`:`, colonX, baseY + 2 * lineHeight);
      doc.text(`:`, colonX, baseY + 3 * lineHeight);
      doc.text(`:`, colonX, baseY + 4 * lineHeight);

      doc.text(`${record.orderId}`, valueX, baseY);
      doc.text(`${record.username}`, valueX, baseY + lineHeight);
      doc.text(`${record.orderDate}`, valueX, baseY + 2 * lineHeight);
      doc.text(`${(record?.orderStatus ?? '')?.toString()?.replace(/_/g, ' ')}`, valueX, baseY + 3 * lineHeight);
      doc.text(`${record.paymentDetails?.paymentStatus}`, valueX, baseY + 4 * lineHeight);


      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      const borderMargin = 7;
      // doc.rect(borderMargin, borderMargin, pageWidth - 2 * borderMargin, pageHeight - 2 * borderMargin);

      const tableHeaders = [
        "NAME",
        "PRICE",
        "QTY",
        "DISCOUNT%",
        // "PAYMENT STATUS",
        "TOTAL"
      ];
      const groupedOrderItems = record.orderItems.reduce((acc, item) => {
        if (!acc[item.productId]) {
          acc[item.productId] = { ...item, quantity: 0, discountedPrice: 0, totalPrice: 0, price: 0, discountPercentage: 0 };
        }
        acc[item.productId].price += item.price;
        acc[item.productId].discountPercentage += item.discountPercentage;
        acc[item.productId].quantity += item.quantity;
        acc[item.productId].discountedPrice += item.discountedPrice;

        return acc;
      }, {});

      const tableData = Object.values(groupedOrderItems).map(item => [
        item.title,
        (item.price ?? 0).toFixed(2),
        item.quantity,
        `${item.discountPercentage}%`,
        // record.paymentDetails.paymentStatus || 'N/A',
        (item.discountedPrice ?? 0).toFixed(2),
      ]);

      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        startY: 40,
        headStyles: {
          fillColor: [27, 58, 87],
          textColor: [255, 255, 255],
        },
        styles: {
          cellPadding: 4,
          minCellHeight: 8,
          lineWidth: 0.2,
          lineColor: [200, 200, 200],
        },
        columnStyles: {
          0: { cellWidth: 37 },
          3: { cellWidth: 37 },
          4: { cellWidth: 29 },// Set the width of the "PRODUCT NAME" column
        },
      });

      const processSteelTableData = (data) => {
        let previousBrand = '';
        let rowspan = 1;
        const processedData = [];

        data.forEach((row) => {
          if (row[0] === previousBrand) {
            // Clear the brand cell if it's not the first row of the same brand group
            row[0] = '';
          } else {
            previousBrand = row[0];
          }
          processedData.push(row);
        });

        return processedData;
      };

      let steelTableData = (record.orderItems || [])
        .filter(item => item.steelProduct)
        .sort((a, b) => parseFloat(a.steelSize) - parseFloat(b.steelSize))
        .map(item => [
          item.brand,
          item.title,
          item.steelSize,
          `${(item.price ?? 0).toFixed(2)}`,
          `${item.discountPercentage}%`,
          item.quantity,
          `${(item.discountedPrice ?? 0).toFixed(2)}`,
        ]);

      // Sort the table data by brand to ensure similar brands are grouped together
      steelTableData.sort((a, b) => a[0].localeCompare(b[0]));

      // Process the table data to group by brand
      steelTableData = processSteelTableData(steelTableData);

      if (steelTableData.length > 0) {
        const steelTableHeaders = [
          "BRAND",
          "PRODUCT NAME",
          "STEEL SIZE",
          "PRICE",
          "DISC %",
          "QTY",
          "DISC PRICE",
        ];

        doc.autoTable({
          head: [steelTableHeaders],
          body: steelTableData,
          startY: doc.lastAutoTable.finalY + 10,
          headStyles: {
            fillColor: [27, 58, 87],
            textColor: [255, 255, 255],
          },
          styles: {
            cellPadding: 4,
            minCellHeight: 8,
            lineWidth: 0.2,
            lineColor: [200, 200, 200],
          },
          columnStyles: {
            0: { cellWidth: 30 },
            2: { cellWidth: 20 },
            4: { cellWidth: 19 },
          },
        });
      }


      const finalY = doc.lastAutoTable.finalY;
      const labelMarginLeft = 137;
      const valueMarginLeft = 174;

      const labelMarginLeft2 = 14;
      const colonMarginLeft2 = 40;
      const valueMarginLeft2 = 42;


      doc.setFontSize(12);
      doc.setTextColor(80);

      const colonMarginLeft = labelMarginLeft + 33; // Adjusting colon position

      doc.text("Subtotal", labelMarginLeft, finalY + 10);
      doc.text(":", colonMarginLeft, finalY + 10);
      doc.text(`${(record.totalPrice ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 10);

      doc.text("Discount Amount", labelMarginLeft, finalY + 20);
      doc.text(":", colonMarginLeft, finalY + 20);
      doc.text(`-${(record.discount ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 20);

      if (record.couponApplied) {
        doc.text("Coupon Savings", labelMarginLeft, finalY + 30);
        doc.text(":", colonMarginLeft, finalY + 30);
        doc.text(`-${(record.couponDiscountedPrice ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30);
      }

      doc.text("Shipping", labelMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
      doc.text(":", colonMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));
      doc.text(`${(record.deliveryFee ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 30 + (record.couponApplied ? 10 : 0));

      doc.line(labelMarginLeft, finalY + 33 + (record.couponApplied ? 10 : 0), valueMarginLeft + 20, finalY + 33 + (record.couponApplied ? 10 : 0));

      doc.text("Total Amount", labelMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
      doc.text(":", colonMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));
      doc.text(`${(record.totalPriceIncludeDeliveryFee ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 40 + (record.couponApplied ? 10 : 0));

      if (record.paymentType === "COD") {
        doc.text("Amount Paid", labelMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
        doc.text(":", colonMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));
        doc.text(`-${(record.paymentDetails.amountReceived ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 50 + (record.couponApplied ? 10 : 0));

        doc.text("Balance Amount", labelMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
        doc.text(":", colonMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
        doc.text(`${((record.totalPriceIncludeDeliveryFee - record.paymentDetails.amountReceived) ?? 0).toFixed(2)}`, valueMarginLeft, finalY + 60 + (record.couponApplied ? 10 : 0));
      }



      doc.setFontSize(11);
      const verticalOffset = record.paymentType === "COD" ? 10 : 0;
      const maxWidth = 90;
      const initialY = finalY + 10;

      // Adjusted colon positions
      const colonMarginLeft3 = colonMarginLeft2 + 2; // Slightly adjusting colon position

      doc.text("Delivery To", labelMarginLeft2, initialY);
      doc.text(":", colonMarginLeft3, initialY);
      doc.text(`${record.shippingAddress.addressType}`, valueMarginLeft2, initialY, { maxWidth });

      doc.text("Payment Type", labelMarginLeft2, initialY + 10);
      doc.text(":", colonMarginLeft3, initialY + 10);
      doc.text(`${record.paymentType}`, valueMarginLeft2, initialY + 10, { maxWidth });

      if (record.paymentType == "COD") {
        doc.text("Payment Id", labelMarginLeft2, initialY + 20);
        doc.text(":", colonMarginLeft3, initialY + 20);
        doc.text(`${record.paymentDetails.paymentId}`, valueMarginLeft2, initialY + 20, { maxWidth });
      }

      doc.text("Mobile No", labelMarginLeft2, initialY + 20 + verticalOffset);
      doc.text(":", colonMarginLeft3, initialY + 20 + verticalOffset);
      doc.text(`+91 ${record.shippingAddress.mobile}`, valueMarginLeft2, initialY + 20 + verticalOffset, { maxWidth });

      doc.text("Address", labelMarginLeft2, initialY + 28 + verticalOffset);
      doc.text(":", colonMarginLeft3, initialY + 28 + verticalOffset);
      const lineHeight1 = 1.5;
      doc.text(
        `${record.shippingAddress.streetAddress}\n${record.shippingAddress.address}\n${record.shippingAddress.city}, ${record.shippingAddress.state}, ${record.shippingAddress.zipCode} ${record.shippingAddress.country}`,
        valueMarginLeft2,
        initialY + 28 + verticalOffset,
        { maxWidth, lineHeightFactor: lineHeight1 }
      );

      const totalPages = doc.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        // Adjust the page number positioning
        doc.text(`Page ${i} of ${totalPages}`, pageWidth - 20, pageHeight - 1, {
          align: 'right',
        });
      }
      const fileName = `Order_${record.orderId}.pdf`;
      doc.save(fileName);

      setTimeout(() => {
        navigate('/');
      });
      setLoading(false);

    }
    setLoading(false);

  };
  useEffect(() => {
    if (record) {
      handleExportRows(record);
    }
  }, [record]);

  return (
    <div>
      <Spin spinning={loading} size="large" tip="">
        <HomeBanner />
        <Sustainability />
        <Clients />
        <Testimonials />
        <FaqGetInTouch />
        <GetApp />
      </Spin>
    </div>
  );
};

export default MyOrdersInvoice;

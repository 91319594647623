import React, { useState, useEffect, useRef } from "react";
import "./ShoppingCart.css";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Instance from "../../AxiosConfig";
import { message, Spin } from "antd";
import { setAddressData } from "../../states/reducers/AddressSlice";
import { useDispatch } from "react-redux";
import { PaymentOptions } from "./PaymentOptions";
import AWS from "aws-sdk";
import { getCurrentLocation, getLocationFromAddress } from "../../awsService";
import MapComponent from "../../AmazonMap";
import { useLocation } from "react-router-dom";

const ShoppingStep2 = ({ next, prev }) => {
  const location = useLocation();
  const selectedLocation = location.state?.location;
  const navigate = useNavigate();
  const [deliverToSelf, setDeliverToSelf] = useState(true);
  const [deliverToOthers, setDeliverToOthers] = useState(false);
  const [selectedAddressSelf, setSelectedAddressSelf] = useState("");
  const [selectedAddressOthers, setSelectedAddressOthers] = useState("");
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const dispatch = useDispatch();
  const [editingAddressId, setEditingAddressId] = useState(null);
  const [errors, setErrors] = useState({});
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [maxAddresses, setMaxAddresses] = useState(3);
  const [savedAddress, setSavedAddress] = useState(true)

  const formRef = useRef(null);
  const [nearestStoreInfoId, setNesarestStoreInfoId] = useState(null);
  const [showAddress, setShowAddress] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false)
  const [addressFields, setAddressFields] = useState({
    firstName: "",
    lastName: "",
    streetAddress: "",
    address: "",
    city: "",
    country: "",
    state: "",
    zipCode: "",
    gstNumber: "",
    phone: "",
  });

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [distance, setDistance] = useState(null);
  const [nearestStore, setNearestStore] = useState(null);
  const [isReadOnlyInput, setIsReadOnlyInput] = useState(false)
  const [checkboxCondition, setCheckboxCondition] = useState(false);

  const [showMap, setShowMap] = useState(false);

  const toggleMap = () => {
    setShowMap(!showMap);
  };
  useEffect(() => {
    setShowForm(false);
  }, []);

  const clearAddressFields = () => {
    setAddressFields({
      firstName: "",
      lastName: "",
      streetAddress: "",
      address: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
      gstNumber: "",
      phone: "",
    });
  };

  const handleLocationSelect = (address) => {
    setAddressFields({
      ...addressFields,
      streetAddress: address.street,
      address: address.landmark,
      city: address.city,
      state: address.state,
      country: address.country,
      zipCode: address.zipCode,

    });
  };



  useEffect(() => {
    if (selectedLocation) {
      setShowForm(true)
      console.log("Selected Location**:", selectedLocation);
      setIsReadOnly(true);
      setAddressFields({
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        lat: null,
        lng: null,
      });


      const { streetAddress, city, state, zipCode, country, lat, lng } = selectedLocation;


      setAddressFields({
        streetAddress,
        city,
        state,
        zipCode,
        country,
        lat,
        lng,
      });
    }
  }, [selectedLocation]);

  const handleSavedAddress = () => {
    setCheckboxCondition(true);
    setSavedAddress(true);
    setShowMap(false);
    setShowForm(false)
  }
  const handleAddAddress = () => {
    setCheckboxCondition(false);
    setShowAddress(true)
    setSelectedAddressSelf("");
    setIsAddingAddress(true)
    setSelectedAddressOthers("");
    setSavedAddress(false)
    setIsReadOnlyInput(false)
    setAddressFields({
      firstName: "",
      lastName: "",
      streetAddress: "",
      address: "",
      city: "",
      country: "",
      state: "",
      zipCode: "",
      gstNumber: "",
      phone: "",
    });
    // window.scrollBy({ top: 200, left: 0, behavior: "smooth" });
    setIsReadOnly(true);
    setShowForm(false);
    setDeliverToSelf(true);
    setDeliverToOthers(false);
    setEditingAddressId(null);
  };
  // window.scrollBy({ top: 100, left: 0, behavior: "smooth" });
  useEffect(() => {
    if (showForm) {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [showForm]);

  const handleAddressChangeSelf = (event) => {
    // setAddressFields({
    //   firstName: "",
    //   lastName: "",
    //   streetAddress: "",
    //   address:  "",
    //   city: "",
    //   country:  "",
    //   state: "",
    //   zipCode: "",
    //   gstNumber:"",
    //   phone: "",
    // });

    setShowCheckbox(true);
    setCheckboxCondition(true);
    setShowForm(true);
    setIsReadOnlyInput(true);
    const selectedAddress = addresses.find(
      (address) => address?.addressId?.toString() === event.target.value
    );
    setSelectedAddressSelf(event.target.value);
    setEditingAddressId(event.target.value);
    window.scrollBy({ top: 300, left: 0, behavior: "smooth" });

    if (selectedAddress.addressType === "SELF") {
      setDeliverToSelf(true);
      setDeliverToOthers(false);
    } else if (selectedAddress.addressType === "OTHERS") {
      setDeliverToSelf(false);
      setDeliverToOthers(true);
    }

    setAddressFields({
      firstName: selectedAddress.firstName,
      lastName: selectedAddress.lastName,
      streetAddress: selectedAddress.streetAddress,
      address: selectedAddress.address || "",
      city: selectedAddress.city,
      country: selectedAddress.country || "",
      state: selectedAddress.state,
      zipCode: selectedAddress.zipCode,
      gstNumber: selectedAddress.gstNumber || "",
      phone: selectedAddress.mobile,
    });

    setIsReadOnly(true);
    // setShowForm(true);
  };

  const handleDeliverToSelfChange = () => {
    setDeliverToSelf(true);
    setDeliverToOthers(false);
    setIsReadOnlyInput(false)
    setShowMap(false);
    setShowForm(false)
    setIsReadOnly(true);
    setSavedAddress(false);
    clearAddressFields();

  };


  const handleInputChange = (e) => {
    const { name, id, value } = e.target;
    let processedValue = value;
    let error = "";

    if (id === "firstName" || id === "lastName") {
      const cleanedValue = value.replace(/[^a-zA-Z\s]/g, "");

      if (id === "firstName") {
        if (cleanedValue.length < 3 || cleanedValue.length > 15) {
          error = "First name must be between 3 and 15 letters.";
        } else {
          error = "";
        }
      }
      setAddressFields({
        ...addressFields,
        [id]: cleanedValue,
      });
    } else if (id === "gstNumber") {
      const gstPattern = /^[0-3][0-9][A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      processedValue = value.toUpperCase();
      if (processedValue && ((!gstPattern.test(processedValue) || (processedValue.length < 15 && processedValue.length > 0)))) {
        error = "Invalid GST number. Please enter a valid 15-character GST number.";
      }
      setAddressFields({
        ...addressFields,
        [id]: processedValue,
      });
    } else {
      setAddressFields({
        ...addressFields,
        [id]: value,
      });
    }

    const regex = /^[a-zA-Z\s]*$/;

    if (id === "phone") {
      if (value.length === 0 || /^[4-9][0-9]{0,9}$/.test(value)) {
        setAddressFields({
          ...addressFields,
          [id]: value,
        });
      } else {
        error = "Mobile number should start with digits 4-9 and contain only up to 10 digits.";
      }
    }

    if (id === "zipCode" && !/^\d*$/.test(value)) {
      error = "Zipcode should contain only digits.";
    }

    if (["city", "state", "country", "firstName", "lastName"].includes(id) && !regex.test(value)) {
      let fieldName = id.charAt(0).toUpperCase() + id.slice(1);

      // Check if the field is firstName or lastName and update accordingly
      if (id === "firstName") {
        fieldName = "First name";
      } else if (id === "lastName") {
        fieldName = "Last name";
      }
      error = `${fieldName} should only contain letters and spaces.`;
    }

    setErrors({
      ...errors,
      [id]: error,
    });
  };


  const handleDeliverToOthersChange = () => {
    setShowMap(true);
    setShowForm(false);
    setIsReadOnlyInput(false);
    setDeliverToOthers(true);
    setDeliverToSelf(false);
    setSavedAddress(false)
    clearAddressFields();
  };

  useEffect(() => {
    AWS.config.update({
      region: 'us-east-1',
      credentials: new AWS.Credentials(
        process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
      ),
    });
  }, []);


  const handleEditDetails = () => {
    setIsAddingAddress(false);
    setIsReadOnlyInput(false);
    setIsReadOnly(false);
  };

  const fetchUserData = async () => {
    try {
      const response = await Instance.get("/api/v1/user/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        setUserData(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const fetchAddressData = async () => {
    try {
      const url = "/api/v1/address/";
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Address data fetched successfully:", response.data);
        setAddresses(response.data);
        if (response.data.length === 0) {
          // setShowForm(true);
          setIsAddingAddress(true);
        }
      }
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  useEffect(() => {
    fetchAddressData();
    fetchUserData();
    setShowForm(false);
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isReadOnly && !isAddingAddress) {
      message.warning("Please save your changes before proceeding.");
      return;
    }
    if (!validateForm()) {
      return;
    }

    if (!latitude || !longitude) {
      const locationData = await handleAddressChange();

      if (locationData) {
        setLatitude(locationData.latitude);
        setLongitude(locationData.longitude);
        // setDistance(locationData.distance);
      } else {

        message.error("Unable to retrieve location. Please verify the address and try again.");
        return;
      }
    }
    const data = {
      email: userData.email,
      addressType: deliverToSelf ? "SELF" : "OTHERS",
      firstName: addressFields.firstName,
      lastName: addressFields.lastName,
      streetAddress: addressFields.streetAddress,
      address: addressFields.address,
      city: addressFields.city,
      state: addressFields.state,
      country: addressFields.country,
      zipCode: addressFields.zipCode,
      mobile: addressFields.phone,
      gstNumber: addressFields.gstNumber,
      latitude: latitude,
      longitude: longitude,
      // distance: distance,
      // nearestStoreId: nearestStoreInfoId,
    };
    console.log("addressbeforepass", data);
    try {
      setLoading(true);
      const response = await Instance.post("/api/v1/orders/create", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("address in response", response);
        setOrderId(response.data.orderId);
        console.log("orderId");
        setAddresses([...addresses, response.data]);
        setShowForm(false);
        dispatch(setAddressData(response.data));
        navigate(`/payment-page/${response.data.orderId}`);
      }
    } catch (error) {
      console.error("Error saving address:", error);
      message.error("Failed to save address.");
    } finally {
      setLoading(false);
    }
  };

  const updateAddress = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      // Call handleAddressChange to fetch latitude and longitude
      await handleAddressChange();

      // Prepare the data object after latitude and longitude are set
      const data = {
        email: userData.email,
        addressType: deliverToSelf ? "SELF" : "OTHERS",
        firstName: addressFields.firstName,
        lastName: addressFields.lastName,
        streetAddress: addressFields.streetAddress,
        address: addressFields.address,
        city: addressFields.city,
        state: addressFields.state,
        country: addressFields.country,
        zipCode: addressFields.zipCode,
        gstNumber: addressFields.gstNumber,
        mobile: addressFields.phone,
        latitude: latitude, // Use the updated latitude
        longitude: longitude, // Use the updated longitude
      };

      console.log("editingAddressId", editingAddressId);

      // Call the API
      const response = editingAddressId
        ? await Instance.put(`/api/v1/address/${editingAddressId}`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        })
        : await Instance.post("/api/v1/orders/create", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        });

      if (response.status === 200) {
        message.success("Address updated successfully!");
        console.log("Updated address", response);
        setAddresses((prev) =>
          prev.map((addr) =>
            addr.addressId === response.data.addressId ? response.data : addr
          )
        );
        setIsReadOnly(true);
        setIsReadOnlyInput(true);
        setIsAddingAddress(true);
      }
    } catch (error) {
      console.error("Error updating address:", error);
      message.error("Failed to update address.");
    }
  };



  const handleGetLocation = async () => {

    setLoading(true)
    if (navigator.geolocation) {

      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          try {
            const response = await getCurrentLocation(latitude, longitude);
            if (response && response.Results && response.Results.length > 0) {
              const place = response.Results[0].Place;
              const city = place.SubRegion || "N/A";
              const state = place.Region || "N/A";
              const country = place.Country || "N/A";
              const postalCode = place.PostalCode || "N/A";
              const fullAddress = place.Label || "";
              const trimmedAddress = fullAddress.split(",").slice(0, 3).join(",").trim();

              setAddressFields((prevFormData) => ({
                ...prevFormData,
                streetAddress: trimmedAddress,
                city: city,
                state: state,
                country: country,
                zipCode: postalCode,
              }));
              setShowForm(true);
            } else {
              message.error("Address not found");
            }
          } catch (error) {
            console.error("Error fetching address:", error);
            message.error("Could not fetch address");
            setLoading(false)
          } finally {
            setLoading(false)
          }
        },
        (error) => {
          setLoading(false);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              message.error("Location access denied. Please enable location services.");
              break;
            case error.POSITION_UNAVAILABLE:
              message.error("Location information is unavailable. Please try again.");
              break;
            case error.TIMEOUT:
              message.error("Location request timed out. Please try again.");
              break;
            default:
              message.error("An unknown error occurred while fetching location.");
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      setLoading(false)
    }
  };

  const fetchCoordinates = async () => {
    try {
      const response = await Instance.get("/api/v1/store-info/all", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Store coordinates:", response.data);
        // calculateStoreDistances(response.data); 

      }
    } catch (error) {
      console.error("Error fetching store data:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };

  const calculateStoreDistances = (stores) => {
    console.log("STORES:", stores)
    if (!latitude || !longitude) return;

    const distances = stores.map((store) => {
      const distance = Math.ceil(calculateDistance(latitude, longitude, store.latitude, store.longitude) * 10) / 10;
      return { ...store, distance };
    });

    console.log("Distances to all stores:", distances);
    const nearest = distances.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr));
    setNearestStore(nearest);
    console.log("NEAREST:", nearest)
    console.group("NEAREST STORE ID", nearest.storeInfoId)
    setNesarestStoreInfoId(nearest.storeInfoId)
    setDistance(nearest.distance);
    console.log("storeInfoId", stores.storeInfoId)
    console.log(`Nearest Store: ${nearest.distance}, Nearest Store: ${nearest.storeName}, Distance: ${nearest.distance} km `);
  };

  const handleAddressChange = async () => {
    const { streetAddress, address, city, state, country, zipCode } = addressFields;
    const fullAddress = `${streetAddress}, ${address}, ${city}, ${state}, ${country}, ${zipCode}`;

    try {
      const response = await getLocationFromAddress(fullAddress);
      console.log("GET LOcation response", response)
      if (response && response.Results && response.Results.length > 0) {
        const position = response.Results[0].Place.Geometry.Point; // [longitude, latitude]
        const addressLatitude = position[1];
        const addressLongitude = position[0];

        setLatitude(addressLatitude);
        setLongitude(addressLongitude);

        fetchCoordinates();
        setAddressFields((prevFormData) => ({
          ...prevFormData,
          latitude: latitude,
          longitude: longitude,
          // distance: distance,
        }));

        console.log(`Latitude: ${latitude}, Longitude: ${longitude}, Distance: ${distance} km`);
      } else {
        message.error("Address not found");
      }
    } catch (error) {
      message.error("An error occurred while fetching location");
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => (degree * Math.PI) / 180;

    const R = 6371; // Radius of the Earth in km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  };

  useEffect(() => {
    if (latitude && longitude) {
      fetchCoordinates();
    }
  }, [latitude, longitude]);
  useEffect(() => {
    if (
      addressFields.streetAddress &&
      addressFields.city &&
      addressFields.state &&
      addressFields.country &&
      addressFields.zipCode
    ) {
      handleAddressChange();
    }
  }, [
    addressFields.streetAddress,
    addressFields.city,
    addressFields.state,
    addressFields.country,
    addressFields.zipCode,
  ]);
  const validateForm = () => {
    const namePattern = /^[A-Za-z\s]{1,32}$/;
    const mobilePattern = /^[4-9][0-9]{9}$/;
    const gstPattern = /^[0-3][0-9][A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const regex = /^[a-zA-Z\s]*$/;
    const digitPattern = /^\d*$/;

    let isValid = true;
    const newErrors = {};

    // First Name Validation
    if (!String(addressFields.firstName || "").trim()) {
      newErrors.firstName = "First name is required.";
      isValid = false;
    } else if (!regex.test(addressFields.firstName)) {
      newErrors.firstName = "First name should only contain letters and spaces.";
      isValid = false;
    } else {
      const letterCount = addressFields.firstName.replace(/\s+/g, "").length;
      if (letterCount < 3 || letterCount > 15) {
        newErrors.firstName = "First name must be between 3 and 15 letters.";
        isValid = false;
      }
    }

    // Last Name Validation
    if (addressFields.lastName && !regex.test(addressFields.lastName)) {
      newErrors.lastName = "Last name should only contain letters and spaces.";
      isValid = false;
    } else if (addressFields.lastName && !namePattern.test(addressFields.lastName.trim())) {
      newErrors.lastName = "Last name must be 1-32 characters and contain only letters.";
      isValid = false;
    }

    // Phone Number Validation
    const phone = String(addressFields.phone || "").trim();
    if (!phone) {
      newErrors.phone = "Mobile number is required.";
      isValid = false;
    } else if (!mobilePattern.test(phone)) {
      newErrors.phone = "Mobile number should start with digits 4-9 and contain only up to 10 digits.";
      isValid = false;
    }

    // City Validation
    const city = String(addressFields.city || "").trim();
    if (!city) {
      newErrors.city = "City is required.";
      isValid = false;
    } else if (!regex.test(city)) {
      newErrors.city = "City should only contain letters and spaces.";
      isValid = false;
    }

    // State Validation
    const state = String(addressFields.state || "").trim();
    if (!state) {
      newErrors.state = "State is required.";
      isValid = false;
    } else if (!regex.test(state)) {
      newErrors.state = "State should only contain letters and spaces.";
      isValid = false;
    }

    // Zip Code Validation
    const zipCode = String(addressFields.zipCode || "").trim();
    if (!zipCode) {
      newErrors.zipCode = "ZipCode is required.";
      isValid = false;
    } else if (!digitPattern.test(zipCode)) {
      newErrors.zipCode = "ZipCode should contain only digits.";
      isValid = false;
    }

    // Country Validation
    const country = String(addressFields.country || "").trim();
    if (!country) {
      newErrors.country = "Country is required.";
      isValid = false;
    } else if (!regex.test(country)) {
      newErrors.country = "Country should only contain letters and spaces.";
      isValid = false;
    }

    // GST Number Validation
    const gstNumber = String(addressFields.gstNumber || "").trim();
    if (gstNumber && !gstPattern.test(gstNumber)) {
      newErrors.gstNumber = "Invalid GST number. Please enter a valid 15-character GST number.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };




  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 820 && screenWidth < 1280) {
        setMaxAddresses(4);
      } else if (screenWidth >= 1280) {
        setMaxAddresses(3);
      } else {
        setMaxAddresses(3);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className="container shopping-step2-div mt-4 mb-4">
      <div className="d-flex justify-content-between">
        <h3>Customer Information</h3>
      </div>
      <div className="col mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Enter email here"
          value={userData.email}
          readOnly
        />
      </div>
      <div className="d-flex justify-content-between mt-4">
        <h5 className="d-flex text-align-left">Delivery Address</h5>
        <div className="d-flex gap-2">
          {!savedAddress && addresses.length > 0 && (
            <button
              className="faq-get-in-touch-button justify-content-start d-flex"
              onClick={handleSavedAddress}
            >
              Show Saved Address
            </button>
          )}
          {/* {addresses.length > 0 && ( */}
          <button
            className="faq-get-in-touch-button justify-content-start d-flex"
            onClick={handleAddAddress}
          >
            Add Address
          </button>
          {/* )} */}

        </div>
      </div>

      {savedAddress && (
        <>
          <div className="media-add-address address-divs-box mt-4">
            {addresses
              .slice(0, showAllAddresses ? addresses.length : maxAddresses)
              .map((address, index) => (
                <label className="address-container" key={address.addressId}>
                  <input
                    type="radio"
                    id={`selfAddress${address.addressId}`}
                    name="selfAddress"
                    value={address.addressId}
                    checked={selectedAddressSelf === address?.addressId?.toString()}
                    onChange={handleAddressChangeSelf}
                    className="address-radio-button"
                  />
                  <div className="delivery-shopping-step delivery-address">
                    <h6>{`${address.firstName} ${address.lastName || ""}`}</h6>
                    <p>{address.streetAddress}</p>
                    <p>{address.address}</p>
                    <p>{address.state}</p>
                    <p>{`${address.city} - ${address.zipCode}`}</p>
                    <p>{`Phone: ${address.mobile}`}</p>
                  </div>
                </label>
              ))}
          </div>
          {addresses.length > maxAddresses && (
            <div className="justify-content-center d-flex mt-3">
              <button
                type="button"
                className="faq-get-in-touch-button justify-content-start"
                onClick={() => setShowAllAddresses(!showAllAddresses)}
              >
                {showAllAddresses ? "View Less" : "View More"}
              </button>
            </div>
          )}
        </>


      )}


      {/* {!showAddress && showCheckbox && (
        <div className="d-flex gap-4 mb-3 mt-3">
          
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="deliverToSelf"
              checked={deliverToSelf}
              onChange={handleDeliverToSelfChange}
            />
            <label className="form-check-label" htmlFor="deliverToSelf">
              Deliver to Self
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="deliverToOthers"
              checked={deliverToOthers}
              onChange={handleDeliverToOthersChange}
            />
            <label className="form-check-label" htmlFor="deliverToOthers">
              Deliver to Others
            </label>
          </div>
        </div>
      )
      } */}

      {!showAddress && showCheckbox && (
        <div className="d-flex gap-4 mb-3 mt-3">
          {checkboxCondition && deliverToSelf && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="deliverToSelf"
                checked={deliverToSelf}
                onChange={handleDeliverToSelfChange}
              />
              <label className="form-check-label" htmlFor="deliverToSelf">
                Deliver to Self
              </label>
            </div>
          )}

          {checkboxCondition && deliverToOthers && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="deliverToOthers"
                checked={deliverToOthers}
                onChange={handleDeliverToOthersChange}
              />
              <label className="form-check-label" htmlFor="deliverToOthers">
                Deliver to Others
              </label>
            </div>
          )}
        </div>
      )}
      {showAddress && checkboxCondition&&(
        <div className="d-flex gap-4 mb-3 mt-3">
        {deliverToSelf && (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="deliverToSelf"
              checked={deliverToSelf}
              onChange={handleDeliverToSelfChange}
            />
            <label className="form-check-label" htmlFor="deliverToSelf">
              Deliver to Self
            </label>
          </div>
        )}
        {deliverToOthers && (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="deliverToOthers"
              checked={deliverToOthers}
              onChange={handleDeliverToOthersChange}
            />
            <label className="form-check-label" htmlFor="deliverToOthers">
              Deliver to Others
            </label>
          </div>
        )}
      </div>
      )
      }
      {showAddress && !checkboxCondition && (
        <>
          <div className="d-flex gap-4 mb-3 mt-3">
            
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deliverToSelf"
                  checked={deliverToSelf}
                  onChange={handleDeliverToSelfChange}
                />
                <label className="form-check-label" htmlFor="deliverToSelf">
                  Deliver to Self
                </label>
              </div>
           
           
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="deliverToOthers"
                  checked={deliverToOthers}
                  onChange={handleDeliverToOthersChange}
                />
                <label className="form-check-label" htmlFor="deliverToOthers">
                  Deliver to Others
                </label>
              </div>
           



          </div>
          {deliverToSelf && (!savedAddress) && (
            <div className="d-flex gap-2 align-items-center current-location-icon" onClick={handleGetLocation}>
              <svg
                width="40"
                height="40"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleGetLocation}
              >
                <path
                  d="M24.0026 24.5C25.6626 25.0653 26.6693 25.8093 26.6693 26.624C26.6693 28.3973 21.8933 29.8333 16.0026 29.8333C10.1119 29.8333 5.33594 28.396 5.33594 26.624C5.33594 25.8093 6.3426 25.0653 8.0026 24.5M20.0026 13.1667C20.0026 14.2275 19.5812 15.2449 18.831 15.9951C18.0809 16.7452 17.0635 17.1667 16.0026 17.1667C14.9417 17.1667 13.9243 16.7452 13.1742 15.9951C12.424 15.2449 12.0026 14.2275 12.0026 13.1667C12.0026 12.1058 12.424 11.0884 13.1742 10.3382C13.9243 9.58809 14.9417 9.16666 16.0026 9.16666C17.0635 9.16666 18.0809 9.58809 18.831 10.3382C19.5812 11.0884 20.0026 12.1058 20.0026 13.1667Z"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 3.16666C21.412 3.16666 26 7.73733 26 13.2827C26 18.916 21.3373 22.8693 17.0307 25.5573C16.7171 25.7377 16.3617 25.8327 16 25.8327C15.6383 25.8327 15.2829 25.7377 14.9693 25.5573C10.6707 22.8427 6 18.936 6 13.2827C6 7.73733 10.588 3.16666 16 3.16666Z"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div>
                <h5>Use Current Location</h5>
              </div>
            </div>
          )}
          <div>
            {(!showForm && showMap) && <p className="mt-2" style={{ color: "red" }}>Please select an address on the map below to proceed with your delivery.</p>}
            {showMap && <MapComponent onLocationSelect={handleLocationSelect} />}
          </div>

        </>

      )}

      {showForm && (
        <form onKeyDown={handleKeyDown} className="row g-3 mt-2" ref={formRef} onSubmit={handleSubmit}>
          <div className="d-flex checkbox-shopping-step2 mb-1 mt-3">
            {/* <div className="d-flex justify-content-between align-items-center"> */}

            {/* <div className=" d-flex justify-content-end"> */}
            <h4>Address details</h4>

            {editingAddressId && (
              <div className="step2-save-edit-button">
                {!isReadOnly ? (
                  <button
                    type="button"
                    onClick={updateAddress}
                    className="faq-get-in-touch-button"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={handleEditDetails}
                    className="faq-get-in-touch-button buttons-edit-details"
                  >
                    Edit Details
                  </button>
                )}
              </div>
            )}

            {/* </div> */}
            {/* </div> */}
          </div>

          <Spin spinning={loading} size="large" tip="">
            <div className={`row  g-3 ${loading ? 'blurred' : ''}`}>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First name"
                  value={addressFields.firstName}
                  onChange={handleInputChange}
                  readOnly={isReadOnlyInput}
                />
                {errors.firstName && <p className="error">{errors.firstName}</p>}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Last name"
                  value={addressFields.lastName}
                  onChange={handleInputChange}
                  readOnly={isReadOnlyInput}
                />
                {errors.lastName && <p className="error">{errors.lastName}</p>}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  id="streetAddress"
                  placeholder="Address Line -1"
                  value={addressFields.streetAddress}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                />
                {errors.streetAddress && (
                  <p className="error">{errors.streetAddress}</p>
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Enter Landmark"
                  value={addressFields.address}
                  onChange={handleInputChange}
                  readOnly={isReadOnlyInput}
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="City"
                  value={addressFields.city}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                />
                {errors.city && <p className="error">{errors.city}</p>}
              </div>

              <div className="col-md-6">
                {/* <p style={{marginBottom:"0px"}}>Optional</p> */}
                <input type="text"
                  className={`form-control add-address-form ${errors.gstNumber ? "invalid" : ""}`}
                  id="gstNumber"
                  placeholder="Enter GST number (Optional)"
                  name="gstNumber"
                  value={addressFields.gstNumber}
                  maxLength="15"
                  onChange={handleInputChange}
                  readOnly={isReadOnlyInput}
                />
                {errors.gstNumber && <p className="error">{errors.gstNumber}</p>}
              </div>

              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="country"
                  placeholder="Country"
                  value={addressFields.country}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                />
                {errors.country && <p className="error">{errors.country}</p>}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  placeholder="State"
                  value={addressFields.state}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                />
                {errors.state && <p className="error">{errors.state}</p>}
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="zipCode"
                  placeholder="Zip Code"
                  value={addressFields.zipCode}
                  onChange={handleInputChange}
                  readOnly={isReadOnly}
                  maxLength={6}
                />
                {errors.zipCode && <p className="error">{errors.zipCode}</p>}
              </div>
              <div className="col-md-6">
                <input
                  type="phone"
                  className="form-control"
                  id="phone"
                  placeholder="Phone"
                  value={addressFields.phone}
                  onChange={handleInputChange}
                  readOnly={isReadOnlyInput}
                  maxLength={10}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.phone && <p className="error">{errors.phone}</p>}
              </div>
            </div>
            <div className="d-flex shopping-step2-delivery-ops justify-content-end mt-4">
              <div className="shopping-step2-buttons-div">
                <div className="">
                  <button
                    className="next-button-cart d-flex justify-content-center"
                    onClick={() => {
                      prev();
                      window.scrollTo(0, 0);
                    }}
                  >
                    <ArrowLeftOutlined className="mx-2" />
                    Previous
                  </button>
                </div>
                <div className="">
                  <button type="submit"
                    className={`next-button-cart ${!selectedAddressSelf && !isAddingAddress ? "disabled-next-button" : ""
                      }`}
                    onClick={handleSubmit}
                    disabled={!selectedAddressSelf && !isAddingAddress}            >
                    {loading ? (
                      <Spin size="small" />
                    ) : (
                      "Next"
                    )}
                    <ArrowRightOutlined className="mx-2" />
                  </button>
                  {orderId && <PaymentOptions orderId={orderId} />}
                </div>
              </div>
            </div>
          </Spin>
        </form>
      )}


    </div>
  );
};

export default ShoppingStep2;

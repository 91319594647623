import React, { useState, useEffect, useRef } from "react";
import "./Shop.css";
import discountIcon from "../../Assets/icons/discount-icon.png";
import { Rate, Spin, message } from "antd";
import {
  HeartFilled,
  HeartOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import Instance from "../../AxiosConfig";
import { IoIosInformationCircle } from "react-icons/io";
import { IoCopyOutline } from "react-icons/io5";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import INRrupee from "../Home/INRrupee";
import { addItem, addItems } from "../../states/reducers/AddToCartSlice";
import { useDispatch } from "react-redux";
import { addWishlist, addWishlists, removeWishlist } from "../../states/reducers/wishlistSlice";
import default_image from "../../Assets/SKImages/defailt-no-image.png";
import { PRODUCT_BASE_URL } from "../../globalConstant"


const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow customs-prev arrow-icons-left" onClick={onClick}>
    <LeftOutlined className="arrow-icon" />
  </div>
);

const NextArrow = ({ onClick }) => (
  <div
    className="custom-arrow customs-next arrow-icons-right"
    onClick={onClick}
  >
    <RightOutlined className="arrow-icon" />
  </div>
);

const ProductImage = () => {
  const dispatch = useDispatch()
  const [activeImage, setActiveImage] = useState(null);
  const [product, setProduct] = useState(null);
  const [wishlistData, setWishlistData] = useState([]);
  const [images, setImages] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [showAllCoupons, setShowAllCoupons] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [quantityError, setQuantityError] = useState('');
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(25);
  const quantityTimeoutRef = useRef(null);
  const [current, setCurrent] = useState("first");
  const [cartItem, setCartItem] = useState({});


  const incrementQuantity = () => {
    setQuantity(prev => Math.min(prev + 1, 100000));
  };

  const decrementQuantity = () => {
    setQuantity(prev => Math.max(prev - 1, 25));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (!isNaN(numericValue)) {
      // Update the quantity immediately
      setQuantity(numericValue);

      // Clear any previous timeout
      if (quantityTimeoutRef.current) {
        clearTimeout(quantityTimeoutRef.current);
      }

      // If the value is less than 25, set it to 25 after a 2 second delay
      if (numericValue < 25) {
        quantityTimeoutRef.current = setTimeout(() => {
          setQuantity(25);
        }, 2000); // Delay of 2 seconds
      } else if (numericValue > 100000) {
        setQuantity(100000);
      }
    } else {
      message.error("Please enter a valid number.");
    }
  };

  // Clear timeout when component unmounts to avoid memory leaks
  useEffect(() => {
    return () => {
      if (quantityTimeoutRef.current) {
        clearTimeout(quantityTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);
  const { productId } = useParams();
  useEffect(() => {
    fetchProductDetails();
    fetchCoupons();
    fetchWishlistData();
    // setQuantities(sections.map(() => 0));
  }, [productId]);

  useEffect(() => {
    setQuantities(sections.map(() => 0));
  }, [sections]);

  const fetchProductDetails = async () => {
    setLoading(true);
    try {
      const response = await Instance.get(`/api/v1/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        const productData = response.data;
        setProduct(productData);

        const fetchedImages = [];
        if (productData.imageUrl) {
          fetchedImages.push(`${PRODUCT_BASE_URL}${productData.imageUrl}`);
        }
        if (productData.imageUrl1) {
          fetchedImages.push(`${PRODUCT_BASE_URL}${productData.imageUrl1}`);
        }
        if (productData.imageUrl2) {
          fetchedImages.push(`${PRODUCT_BASE_URL}${productData.imageUrl2}`);
        }
        setImages(fetchedImages);

        if (fetchedImages.length > 0) {
          setActiveImage(fetchedImages[0]);
        }

        setSections([
          {
            id: 1,
            section: "6MM",
            price: productData.size6mmPrice,
            discountedPrice: productData.size6mmDiscountedPrice,
          },
          {
            id: 2,
            section: "8MM",
            price: productData.size8mmPrice,
            discountedPrice: productData.size8mmDiscountedPrice,
          },
          {
            id: 3,
            section: "10MM",
            price: productData.size10mmPrice,
            discountedPrice: productData.size10mmDiscountedPrice,
          },
          {
            id: 4,
            section: "12MM",
            price: productData.size12mmPrice,
            discountedPrice: productData.size12mmDiscountedPrice,
          },
          {
            id: 5,
            section: "16MM",
            price: productData.size16mmPrice,
            discountedPrice: productData.size16mmDiscountedPrice,
          },
          {
            id: 6,
            section: "20MM",
            price: productData.size20mmPrice,
            discountedPrice: productData.size20mmDiscountedPrice,
          },
          {
            id: 7,
            section: "25MM",
            price: productData.size25mmPrice,
            discountedPrice: productData.size25mmDiscountedPrice,
          },
          {
            id: 8,
            section: "32MM",
            price: productData.size32mmPrice,
            discountedPrice: productData.size32mmDiscountedPrice,
          },
        ]);

        if (productData.coupons && productData.coupons.length > 0) {
          setCoupons(productData.coupons);
        } else {
          setCoupons([]);
        }
      }
      setLoading(false)
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
        "An error occurred while fetching product details"
      );
      setLoading(false)
    }
    setLoading(false)
  };

  const fetchWishlistData = async () => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const url = `/api/v1/wishlist/?sessionId=${sessionId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        setWishlistData(response.data);

        dispatch(addWishlists(response.data))
        console.log("wishlistData", response.data)
      }
    } catch (error) {
      console.error(error?.response?.data?.message || "An error occurred");
    }
  };
  const addToWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem('sessionId', sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem('sessionId');
      }
      const url = `/api/v1/wishlist/products/${productId}${sessionId ? `?sessionId=${sessionId}` : ''}`;
      const response = await Instance.post(url, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success(response.data.message);
        dispatch(addWishlist(response.data))
        fetchWishlistData();
        // fetchWishlistData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred while adding to wishlist");
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      let sessionId = sessionStorage.getItem('sessionId');
      const url = `/api/v1/wishlist/product/${productId}?sessionId=${sessionId}`;
      const response = await Instance.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        message.success(response?.data?.message);

        dispatch(removeWishlist(productId))
        fetchWishlistData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    // fetchCementProducts();
    fetchWishlistData();
  }, []);

  useEffect(() => {
    const isProductLiked = wishlistData.some(item => {
      return item.productId === Number(productId);
    });
    setIsLiked(isProductLiked);
  }, [wishlistData, productId]);
  const toggleLike = async (e) => {
    e.stopPropagation();
    if (isLiked) {
      await removeFromWishlist(productId);
    } else {
      await addToWishlist(productId);
    }
    setIsLiked(!isLiked);
  };


  const fetchCoupons = async () => {
    try {
      const response = await Instance.get(`/api/v1/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Coupons:", response.data.coupons);
        setCoupons(response.data.coupons);
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
        "An error occurred while fetching coupons"
      );
    }
  };



  const handleImageClick = (imgSrc) => {
    setActiveImage(imgSrc);
  };

  const handleQuantityChange = (index, value) => {

    const valueStr = value.toString();

    if (valueStr.startsWith('0') && valueStr.length > 1) {
      return;
    }
    const parsedValue = valueStr === '' ? '' : parseInt(valueStr, 10) || 0;
    if (parsedValue < 0) return;

    const limitedValue = parsedValue > 100000 ? 100000 : parsedValue;

    const newQuantities = [...quantities];
    newQuantities[index] = limitedValue;
    setQuantities(newQuantities);
  };

  const handleFocus = (index) => {
    const newQuantities = [...quantities];
    if (newQuantities[index] === 0) {
      newQuantities[index] = '';
    }
    setQuantities(newQuantities);
  };

  const handleBlur = (index) => {
    const newQuantities = [...quantities];
    if (newQuantities[index] === '' || isNaN(newQuantities[index])) {
      newQuantities[index] = 0;
    }
    setQuantities(newQuantities);
  };

  const calculateTotal = (price, quantity) => price * (quantity || 0);

  const calculateOverallTotal = () => {
    return sections.reduce(
      (total, section, index) =>
        total + calculateTotal(section?.discountedPrice, quantities[index]),
      0
    );
  };

  const calculateTotalQuantity = () => {
    return quantities.reduce((total, quantity) => total + (quantity || 0), 0);
  };

  // if (!product) {
  //   return <div>Loading...</div>;
  // }

  const handleAddToCart = async () => {
    console.log("QUANTITYY:", quantity)
    if (quantity < 25) {
      message.error("The quantity should be more than 25.");
      return;
    }
    try {
      const totalQuantity = calculateTotalQuantity();

      // if (isSteelProduct && totalQuantity < 150) {
      //   message.error("The steel product quantity must be greater than 150kg");
      //   return;
      // }


      const cartItemDtos = sections
        .map((section, index) => {
          const quantity = quantities[index];
          if (quantity > 0) {
            return {
              quantity,
              steelSize: section.section.toLowerCase(),
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      if (cartItemDtos.length === 0 && isSteelProduct) {
        message.error("Please add quantities for steel sections.");
        return;
      }

      await addToCart(productId, cartItemDtos);
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
        "An error occurred while adding to cart"
      );
    }
  };

  const handleBuyNow = async () => {
    try {
      const totalQuantity = calculateTotalQuantity();

      // if (isSteelProduct && totalQuantity < 150) {
      //   message.error("The steel product quantity must be greater than 150kg");
      //   return;
      // }

      if (isSteelProduct) {
        const cartItemDtos = sections
          .map((section, index) => {
            const quantity = quantities[index];
            if (quantity > 0) {
              return {
                quantity,
                steelSize: section.section.toLowerCase(),
              };
            }
            return null;
          })
          .filter((item) => item !== null);

        if (cartItemDtos.length === 0) {
          message.error("Please add quantities for steel sections.");
          return;
        }

        await BuyNow(productId, cartItemDtos);
      } else {
        await BuyNow(productId, []);
      }
    } catch (error) {
      message.error(
        error.response?.data?.message ||
        "An error occurred while adding to cart"
      );
    }
  };

  const handleCopy = (couponCode) => {
    navigator?.clipboard?.writeText(couponCode);
    message.success('Coupon copied!');
  };

  const addToCart = async (productId, cartItemDtos = []) => {
    console.log("QUANTITY", quantity)
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem("sessionId", sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem("sessionId");
      }
      const url = `api/v1/cart/add/${productId}${sessionId || quantity
        ? `?${sessionId ? `sessionId=${sessionId}&` : ""}${quantity ? `quantity=${quantity}` : ""}`
        : ""
        }`;
      setLoading(true)
      const response = await Instance.post(url, cartItemDtos, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        dispatch(addItem(response.data))
        fetchCartCount();
        message.success(response.data.message);
        fetchCartData();
        console.log(response);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while adding to cart";
      message.error(errorMessage);
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  const fetchCartCount = async () => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const response = await Instance.get(
        `/api/v1/cart/?sessionId=${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(addItems(response.data.cartItems))
      }

    } catch (error) {
      console.error("Error fetching cart count:", error);
    }
  };
  const BuyNow = async (productId, cartItemDtos = []) => {
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        if (!sessionId) {
          sessionId = generateSessionId();
          sessionStorage.setItem("sessionId", sessionId);
        }
      } else {
        sessionId = "";
        sessionStorage.removeItem("sessionId");
      }
      setLoading(true)
      const url = `api/v1/cart/add/${productId}${sessionId || quantity
        ? `?${sessionId ? `sessionId=${sessionId}&` : ""}${quantity ? `quantity=${quantity}` : ""}`
        : ""
        }`;
      const response = await Instance.post(url, cartItemDtos, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        message.success(response.data.message);
        dispatch(addItem(response.data))
        fetchCartCount();
        console.log(response);
        navigate("/cart");
      }
    } catch (error) {
      message.error(
        error.response?.data?.message ||
        "An error occurred while adding to cart"
      );
      setLoading(false)
      console.log("Error:", error.response);
    } finally {
      setLoading(false)
    }
  };
  const generateSessionId = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const isSteelProduct =
    product?.category.categoryTitle.toLowerCase() === "steel".toLowerCase();
  const handleShowMoreCoupons = () => {
    setShowAllCoupons(true);
  };
  const handleHideMoreCoupons = () => {
    setShowAllCoupons(false);
  };
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
    ],
  };

  const handleScrollToReviews = () => {
    setCurrent("second");

    navigate(`/product-details/${productId}`, { state: { currentTab: "second" } });

    setTimeout(() => {
      window.scrollTo({
        top: 600,
        behavior: 'smooth',
      });
    }, 500);
  };
  const fetchCartData = async () => {
    // setLoading(true);
    try {
      let sessionId = sessionStorage.getItem("sessionId");
      const url = `/api/v1/cart/?sessionId=${sessionId}`;
      const response = await Instance.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("CART DATA (product images page)", response);
        setCartItem(response.data)
        // const groupedItems = groupCartItems(response.data.cartItems);
        // console.log("grouped", groupedItems);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCartData(); 
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <div className="product-detail-main">
          <div className="product-images">
            {images.length >= 0 && (
              <>
                <div className="small-images">
                  {images.map((imgSrc, index) => (
                    <img
                      key={index}
                      src={imgSrc}
                      alt={`Product ${index + 1}`}
                      className={activeImage === imgSrc ? "active" : ""}
                      onClick={() => handleImageClick(imgSrc)}
                    />
                  ))}
                </div>
                <div className="product-left-detail">
                  <img
                    src={activeImage || default_image}
                    alt={product?.title || "Default Image"}
                  />
                </div>
              </>
            )}
            <div className="Heart-outlined media-heart-outlined" style={{ marginLeft: "-40px", marginTop: "10px" }} onClick={toggleLike}>
              {isLiked ? (
                <HeartFilled style={{ color: "red", fontSize: "30px" }} />
              ) : (
                <HeartOutlined style={{ fontSize: "30px" }} />
              )}
            </div>
          </div>
          <div className="product-detail-info">
            {!isSteelProduct && (
              <>
                <h2 className="productImage-title">{product?.title}</h2>
                <p className="product-detail-description">{product?.description}</p>
                <div className="d-flex gap-4">
                  <Rate
                    disabled
                    allowHalf
                    value={product?.numRatings}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                    }}
                  />
                  <a
                    href="#"
                    onClick={handleScrollToReviews}
                    style={{ color: "#1b3a57", textDecoration: "none" }}
                  >
                    {product?.totalReviewsFormatted} Reviews
                  </a>
                </div>
                <div className="product-detail-price">
                  <span className="product-current-price">
                    <INRrupee />{product?.discountedPrice}
                  </span>
                  <span className="product-original-price"><INRrupee />{product?.price}</span>
                  <span className="product-detail-discount">{product?.discountPercentage}% off</span>
                </div>
                <div>
                  <p className="product-image-gst"> 28% GST has been applied to the cement</p>
                </div>

                <div>
                  <div className="d-flex align-items-center gap-4 mb-3">
                    <div><p style={{ marginBottom: "0px", fontSize: "14px" }} >Minimum Quantity is 25 bags</p></div>
                    <div className="quantity-cart-selector mt-0 d-flex justify-content-end">
                      <button className="quantity-button" onClick={decrementQuantity}>
                        -
                      </button>
                      <input
                        type="text"
                        className="quantity-change-input"
                        value={quantity}
                        onChange={handleInputChange}
                      />
                      <button className="quantity-button" onClick={incrementQuantity}>
                        +
                      </button>
                    </div>
                  </div>

                  <div className="d-flex gap-2 ">
                    {/* <button className="product-add-to-cart" onClick={handleAddToCart}>
                      {loading ? (
                        <Spin size="small" />
                      ) : (
                        "Add to cart"
                      )}
                    </button> */}
                    <button
                      className="product-add-to-cart"
                      onClick={handleAddToCart}
                    >
                      {/* <ShoppingCartOutlined className="pe-2" /> */}
                      {cartItem.cartItems?.some(item => item?.productId === product?.productId) ? (
                        <span dangerouslySetInnerHTML={{ __html: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Added&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" }} />
                      ) : (
                        loading ? (
                          <Spin size="small" />
                        ) : (
                          "Add To Cart"
                        )
                      )}
                    </button>
                    <button
                      className="product-buy-now"
                      onClick={handleBuyNow}
                      style={{
                        borderRadius: "20px",
                        padding: "10px 25px",
                        backgroundColor: "#e6effd",
                        color: "#1b3a57",
                        width: "100%",
                        border: "none",
                      }}
                    >
                      {loading ? (
                        <Spin size="small" />
                      ) : (
                        "Buy Now"
                      )}

                    </button>
                  </div>
                </div>
              </>
            )}
            {isSteelProduct && (
              <div>
                <h2>{product?.title}</h2>
                <p className="product-detail-description" style={{ marginBottom: "3px" }}>
                  {product?.description}
                </p>
                <div>
                  <p className="product-image-gst-steel"> 18% GST has been applied to the Steel</p>
                </div>
                {/* <div className="d-flex gap-4">
             <Rate
            disabled
            allowHalf
              defaultValue={product.numRatings}
             style={{
             display: "flex",
              justifyContent: "start",
              }}
           />
           <a
              href="#"
             style={{ color: "#1b3a57", textDecoration: "none" }}
            >
              {product.totalReviewsFormatted} Reviews
             </a>
             </div> */}
                <div className="card card-new-steel mt-2 mb-2">
                  <div className="header-new-steel d-flex pb-2">
                    <p>Section</p>
                    <p>Price</p>
                    <p>Quantity</p>
                    <p>Total</p>
                  </div>
                  {sections.map((section, index) => (
                    <div key={section.id} className="row row-new-steel  ">
                      <p style={{ width: "60px" }}>{section.section}</p>
                      <p
                        style={{
                          border: "1px solid",
                          borderRadius: "8px",
                          padding: "2px 5px 2px 5px",
                          width: "80px",
                        }}
                      >
                        <INRrupee /> {section?.discountedPrice}
                      </p>
                      <input
                        type="number"
                        value={quantities[index]}
                        onFocus={() => handleFocus(index)}
                        onChange={(e) =>
                          handleQuantityChange(index, e.target.value)
                        }
                        onBlur={() => handleBlur(index)}
                        className="responsive-input-number"
                      />
                      <p
                        style={{
                          border: "1px solid",
                          borderRadius: "8px",
                          padding: "2px 5px 2px 5px",
                          width: "80px",
                        }}
                      >
                        <INRrupee />{" "}
                        {calculateTotal(
                          section?.discountedPrice,
                          quantities[index]
                        )}
                      </p>
                    </div>
                  ))}
                  <div className="d-flex gap-4 justify-content-end align-items-center mt-2 total-order-quantity-div">
                    <h5>Total: </h5>
                    <p
                      style={{
                        border: "1px solid #1b3a57",
                        padding: "2px 5px",
                        borderRadius: "8px",
                        width: "80px",
                      }}
                    >
                      {calculateTotalQuantity()}
                    </p>
                    <p
                      style={{
                        border: "1px solid #1b3a57",
                        padding: "2px 5px",
                        borderRadius: "8px",
                        width: "82px",
                        marginRight: "-10px",
                        textAlign: "center",
                      }}
                    >
                      <INRrupee />{calculateOverallTotal()}
                    </p>
                  </div>
                </div>


                <div className="d-flex justify-content-end gap-4">
                  {/* <button
                    className="product-add-to-card-steel"
                    onClick={handleAddToCart}
                  >
                    {loading ? (
                      <Spin size="small" />
                    ) : (
                      "Add to cart"
                    )}

                  </button> */}
                  <button
                    className="product-add-to-card-steel"
                    onClick={handleAddToCart}
                  >
                    {/* <ShoppingCartOutlined className="pe-2" /> */}
                    {cartItem.cartItems?.some(item => item?.productId === product?.productId) ? (
                      <span dangerouslySetInnerHTML={{ __html: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Added&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" }} />
                    ) : (
                      loading ? (
                        <Spin size="small" />
                      ) : (
                        "Add To Cart"
                      )
                    )}
                  </button>
                  <button
                    className="product-buy-now-steel"
                    onClick={handleBuyNow}
                  >
                    {loading ? (
                      <Spin size="small" />
                    ) : (
                      "Buy Now"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>



        </div>
      </Spin >
      <div className="col-12 d-flex discount-main">
        <div className="col-12 col-md-6 d-flex gap-4 justify-content-center mobile-promo">
          <img
            style={{ width: "50px", height: "50px" }}
            src={discountIcon}
            alt="Discount Icon"
          />
          <div>
            <h5 className="mb-0">Voucher Promo</h5>
            {coupons.length > 0 ? (
              <p>There are {coupons.length} promo codes for you</p>
            ) : (
              <p>No promo codes available</p>
            )}

          </div>
        </div>
        {coupons.length > 0 && (
          <div className="col-12 col-md-6 width-slider">
            {isMobile ? (
              coupons.length > 1 ? (
                <Slider {...sliderSettings}>
                  {coupons.map(
                    (coupon, index) =>
                      coupon.status === "enabled" && (
                        <div key={index} className="voucher-bg">
                          <div className="d-flex justify-content-center gap-4">
                            <h6 className="mb-0">
                              {coupon.couponType?.toUpperCase() === "PERCENTAGE"
                                ? `Get up to ${coupon.value}% off`
                                : coupon.couponType?.toUpperCase() ===
                                  "DISCOUNT_AMOUNT"
                                  ? `Flat ${coupon.value} Rs off`
                                  : coupon.couponType?.toUpperCase() ===
                                    "FREE_SHIPPING"
                                    ? "Free Shipping"
                                    : ""}
                            </h6>
                            <IoIosInformationCircle
                              style={{ color: "#b1b1b1", fontSize: "22px" }}
                            />
                          </div>
                          <p className="line-para">Essential material</p>
                          <div className="d-flex justify-content-center gap-4 pt-2">
                            <p>{coupon.couponCode}</p>
                            <IoCopyOutline
                              style={{ fontSize: "20px", cursor: "pointer" }}
                              onClick={() => handleCopy(coupon.couponCode)}
                            />
                          </div>
                        </div>
                      )
                  )}
                </Slider>
              ) : (
                <div className="static-coupons d-flex gap-4">
                  {coupons.map(
                    (coupon, index) =>
                      coupon.status === "enabled" && (
                        <div key={index} className="voucher-bg">
                          <div className="d-flex justify-content-center gap-4">
                            <h6 className="mb-0">
                              {coupon.couponType?.toUpperCase() === "PERCENTAGE"
                                ? `Get up to ${coupon.value}% off`
                                : coupon.couponType?.toUpperCase() ===
                                  "DISCOUNT_AMOUNT"
                                  ? `Flat ${coupon.value} Rs off`
                                  : coupon.couponType?.toUpperCase() ===
                                    "FREE_SHIPPING"
                                    ? "Free Shipping"
                                    : ""}
                            </h6>
                            <IoIosInformationCircle
                              style={{ color: "#b1b1b1", fontSize: "22px" }}
                            />
                          </div>
                          <p className="line-para">Essential material</p>
                          <div className="d-flex justify-content-center gap-4 pt-2">
                            <p>{coupon.couponCode}</p>
                            <IoCopyOutline
                              style={{ fontSize: "20px", cursor: "pointer" }}
                              onClick={() => handleCopy(coupon.couponCode)}
                            />
                          </div>
                        </div>
                      )
                  )}
                </div>
              )
            ) : coupons.length > 3 ? (
              <Slider {...sliderSettings}>
                {coupons.map(
                  (coupon, index) =>
                    coupon.status === "enabled" && (
                      <div key={index} className="voucher-bg">
                        <div className="d-flex justify-content-center gap-4">
                          <h6 className="mb-0">
                            {coupon.couponType?.toUpperCase() === "PERCENTAGE"
                              ? `Get up to ${coupon.value}% off`
                              : coupon.couponType?.toUpperCase() ===
                                "DISCOUNT_AMOUNT"
                                ? `Flat ${coupon.value} Rs off`
                                : coupon.couponType?.toUpperCase() ===
                                  "FREE_SHIPPING"
                                  ? "Free Shipping"
                                  : ""}
                          </h6>
                          <IoIosInformationCircle
                            style={{ color: "#b1b1b1", fontSize: "22px" }}
                          />
                        </div>
                        <p className="line-para">Essential material</p>
                        <div className="d-flex justify-content-center gap-4 pt-2">
                          <p>{coupon.couponCode}</p>
                          <IoCopyOutline
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={() => handleCopy(coupon.couponCode)}
                          />
                        </div>
                      </div>
                    )
                )}
              </Slider>
            ) : (
              <div className="static-coupons d-flex gap-4">
                {coupons.map(
                  (coupon, index) =>
                    coupon.status === "enabled" && (
                      <div key={index} className="voucher-bg">
                        <div className="d-flex justify-content-center gap-4">
                          <h6 className="mb-0">
                            {coupon.couponType?.toUpperCase() === "PERCENTAGE"
                              ? `Get up to ${coupon.value}% off`
                              : coupon.couponType?.toUpperCase() ===
                                "DISCOUNT_AMOUNT"
                                ? `Flat ${coupon.value} Rs off`
                                : coupon.couponType?.toUpperCase() ===
                                  "FREE_SHIPPING"
                                  ? "Free Shipping"
                                  : ""}
                          </h6>
                          <IoIosInformationCircle
                            style={{ color: "#b1b1b1", fontSize: "22px" }}
                          />
                        </div>
                        <p className="line-para">Essential material</p>
                        <div className="d-flex justify-content-center gap-4 pt-2">
                          <p>{coupon.couponCode}</p>
                          <IoCopyOutline
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={() => handleCopy(coupon.couponCode)}
                          />
                        </div>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductImage;

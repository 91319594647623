import React from "react";
import { useNavigate } from "react-router-dom";

const ShippingInfo = () => {
    const navigate = useNavigate();
    
    const handleContact = () => {
        navigate("/contact-us");
    };

    return (
        <>
            <div className="container-fluid" style={{ overflow: "hidden" }}>
                <div className="row shop-banner-row">
                    <div className="col-md-6 justify-content-center col-12 banner-content-contact px-5">
                        <h5 className="banner-header-returns" style={{ fontWeight: "700", fontStyle: "italic", fontSize: "48px", color: "#fff" }}>
                            Shipping Information
                        </h5>
                        <p style={{ color: "#fff" }}>
                            At Builderneed, we are committed to ensuring that your orders are delivered promptly and efficiently. Please review the shipping details for more information.
                        </p>
                        <button className="explore-button-shop" onClick={handleContact}>
                            Contact Us
                        </button>
                    </div>
                    
                </div>
            </div>

            <div className="container mt-3 mb-3" style={{padding:"30px", textAlign: 'left', backgroundColor:"#f8f9fa", borderRadius:"10px" }}>
                <h5>Order Processing & Shipping Time:</h5>
                <p>
                    <strong>Order Processing:</strong> Once your order is placed, we begin processing it within 1-2 business days. You will receive a confirmation email with your order details and tracking information once your order has shipped.
                </p>
                <p>
                    <strong>Shipping Time:</strong> Depending on your location, delivery typically takes 3-7 business days after your order has been processed. Please note that delays may occur during peak seasons or due to unforeseen circumstances.
                </p>

                <h5>Shipping Costs:</h5>
                <ul>
                    <li><strong>Standard Shipping:</strong> Shipping costs are calculated based on the distance between our warehouse and your delivery location. The exact shipping charges will be displayed at checkout.</li>
                    <li><strong>Free Shipping:</strong> Free standard shipping is available only when a valid coupon is applied at checkout. Without a coupon, standard shipping charges will apply, determined by the distance of delivery.</li>
                    <li><strong>Expedited Shipping:</strong> If you need your items sooner, we offer expedited shipping options at an additional cost. The shipping fee for expedited delivery will depend on the distance and will be shown during the checkout process.</li>
                </ul>

                <p>
                    For any questions about your order or shipping status, feel free to contact our customer support team at <a href="mailto:support@skcements.com">support@skcements.com</a>. We are happy to assist you!
                </p>
            </div>
        </>
    );
};

export default ShippingInfo;

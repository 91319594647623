import React from 'react';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
    const navigate = useNavigate();
    const handleContact = () => {
        navigate("/contact-us");
    };
    return (
        <>
            <div className="container-fluid" style={{ overflow: "hidden" }}>
                <div className="row shop-banner-row">
                    <div className="col-md-6 justify-content-center col-12 banner-content-contact px-5">
                        <h5 className="banner-header-returns" style={{ fontWeight: "700", fontStyle: "italic", fontSize: "48px", color: "#fff" }}>
                            Terms and Conditions
                        </h5>
                        <p className='terns-conditions-bannner' style={{  color: "#fff" }}>
                            Welcome to Builderneed! Please take a moment to review our terms and conditions, which govern your use of our services. Your continued use signifies your agreement to these terms.
                        </p>
                        <button className="explore-button-shop" onClick={handleContact}>
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>

            <div className="container mt-3 mb-3" style={{ padding: "30px", textAlign: 'left', backgroundColor: "#f8f9fa", borderRadius: "10px" }}>
                <h2 className="text-center">Terms and Conditions</h2>

                <h5>Contact Information:</h5>
                <p>If you have any questions or need assistance regarding these terms and conditions, feel free to reach us at:</p>
                <address>
                    <strong>Builderneed</strong><br />
                    Email: <a href="mailto:support@skcements.com">support@skcements.com</a><br />
                    Phone: +91 8660519994<br />
                    Address: Sri Krishna Enterprise<br />
                    383, Ground floor, 9th Main, 7th Sector, HSR Layout,<br />
                    Bangalore, Karnataka, 560102, India
                </address>

                <h5>Effective Date for Policy:</h5>
                <p>These terms and conditions are currently in effect. Builderneed reserves the right to update or modify these terms at any time. Any changes will be posted on this page, and your continued use of our services after such updates signifies your agreement to the revised terms.</p>

                <h5>Limitation of Liability and Disclaimer of Warranties:</h5>
                <p>Builderneed strives to provide accurate and up-to-date information on its website and through its services. However, we do not guarantee the accuracy, completeness, or reliability of any information or products available on our platform. All services and materials are provided “as is” without any warranties, whether express or implied, including, but not limited to, implied warranties of merchantability or fitness for a particular purpose.</p>
                <p>Builderneed is not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to use our products or services, including but not limited to loss of profits, data, or goodwill. You agree to use our services at your own risk.</p>

                <h5>Rules of Conduct:</h5>
                <p>When using Builderneed’s website and services, you agree to the following rules of conduct:</p>
                <ul>
                    <li>Provide accurate and up-to-date information when registering or placing an order.</li>
                    <li>Use our services only for lawful purposes and refrain from infringing on any intellectual property or proprietary rights.</li>
                    <li>Do not attempt to access, hack, or disrupt the integrity of our website or servers.</li>
                    <li>Avoid submitting harmful, offensive, or inappropriate content, including viruses, malware, or phishing attempts.</li>
                </ul>
                <p>Builderneed reserves the right to terminate or restrict your access to our services if these rules of conduct are violated.</p>

                <h5>User Restrictions:</h5>
                <p>Users are prohibited from:</p>
                <ul>
                    <li>Copying, distributing, or modifying any part of our website or content without express permission.</li>
                    <li>Using automated systems (such as bots or scraping tools) to extract data from our website without prior authorization.</li>
                    <li>Misrepresenting your identity or affiliation with any person or entity.</li>
                    <li>Engaging in activities that harm, interfere with, or circumvent the security of our systems or the services we provide.</li>
                    <li>Using our platform to engage in fraudulent, illegal, or unauthorized activities.</li>
                </ul>
                <p>Violating any of these restrictions may result in legal action, as well as the suspension or termination of your account.</p>
            </div>
        </>
    );
};

export default TermsAndConditions;

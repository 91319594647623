import logo from './logo.svg';
import { useState, useEffect } from 'react';
import './App.css';
import AppRoutes from './Router';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTopButton from "./Components/ScrollToTop/ScrollToTopButton";
import TokenExpiredPopup from './Components/Authentication/TokenExpiryPopUp';
import { jwtDecode } from "jwt-decode"; // Correct import for jwtDecode
import { useDispatch, useSelector } from 'react-redux';
import { setTokenExpiredPopupVisible } from './states/reducers/AuthSlice';

function App() {
  const dispatch = useDispatch();
  const isTokenExpiredPopupVisible = useSelector(state => state.auth.isTokenExpiredPopupVisible);

  const checkTokenExpiry = () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp && decodedToken.exp < currentTime) {
          dispatch(setTokenExpiredPopupVisible(true));
          localStorage.removeItem('authToken');
        } else {
          console.log('Token is valid.');
        }
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    } else {
      console.log('No authToken found.');
    }
  };

  useEffect(() => {
    checkTokenExpiry();

    const intervalId = setInterval(() => {
      checkTokenExpiry();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleClosePopup = () => {
    dispatch(setTokenExpiredPopupVisible(false));
  };

  useEffect(() => {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/67a339303a8427326079f1d2/1ijapeq9k';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    })();
}, []);




  return (
    <div className="App">
      <AppRoutes />
      <ScrollToTopButton />
      <TokenExpiredPopup
        visible={isTokenExpiredPopupVisible}
        onClose={handleClosePopup}
      />
    </div>
  );
}

export default App;

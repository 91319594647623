import React from "react";
import { useNavigate } from "react-router-dom";

const Returns = () => {
    const navigate = useNavigate();

    const handleContact = () => {
        navigate("/contact-us");
    };

    return (
        <>
        <div className="container-fluid" style={{ overflow: "hidden" }}>
                <div className="row shop-banner-row">
                    <div className="col-md-6 justify-content-center col-12 banner-content-contact px-5">
                        <h5 className="banner-header-returns" style={{ fontWeight: "700", fontStyle: "italic", fontSize: "48px", color: "#fff" }}>
                            Cancellation Policy
                        </h5>
                        <p style={{ color: "#fff" }}>
                            At Builderneed, we value your satisfaction and strive to make your shopping experience as seamless as possible. Please review our cancellation and return policies for more information.
                        </p>
                        <button className="explore-button-shop" onClick={handleContact}>
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
            <div className="container mt-3 mb-3" style={{padding:"30px", textAlign: 'left', backgroundColor:"#f8f9fa", borderRadius:"10px" }}>
                <h5>Cancellation Policy</h5>
                <p>
                    At Builderneed, we value your satisfaction and strive to make your shopping experience as seamless as possible. In case you need to cancel, return, or exchange an order, please review the following details:
                </p>

                <h5>Returns & Exchanges:</h5>
                <ul>
                    <li>
                        <strong>Eligibility:</strong> Products can be returned or exchanged within 30 days of the purchase date. To be eligible, items must be in their original condition, unused, and with the original packaging intact.
                    </li>
                </ul>

                <h5>How to Initiate a Return or Exchange:</h5>
                <p>To initiate a return or exchange, please follow these steps:</p>
                <ol>
                    <li>
                        <strong>Contact Us:</strong> Send an email to <a href="mailto:support@skcements.com">support@skcements.com</a> with your order number, details of the item(s) you wish to return or exchange, and the reason for the request.
                    </li>
                    <li>
                        <strong>Approval:</strong> Once your request is received, our team will review it and get back to you within 2-3 business days with instructions on how to proceed.
                    </li>
                    <li>
                        <strong>Shipping:</strong> After approval, you'll receive details on where to send the returned item(s). Please ensure they are securely packed for return shipping.
                    </li>
                </ol>

                <h5>Refund Processing Time:</h5>
                <p>
                    Once we receive your returned item(s) and verify the condition, we will process your refund within 15 days. The refund will be issued to your original method of payment, and you will receive a confirmation email once the refund has been processed.
                </p>

                <h5>Contact Us:</h5>
                <p>
                    For any additional questions or concerns, please don't hesitate to reach out to our customer support team at <a href="mailto:support@skcements.com">support@skcements.com</a>.
                </p>
            </div>
        </>
    );
};

export default Returns;

import React, { useState, useEffect } from "react";
import "./details.css";
import Instance from "../../../AxiosConfig";
import { message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { countries } from "countries-list";
import AWS from "aws-sdk";
import { getCurrentLocation, getLocationFromAddress } from "../../../awsService";
export const AddAdress = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    streetAddress: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    mobile: "",
    gstNumber:"",
    setAsDefaultBilling: false,
  });
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/profile/details");
  };
  const [loading, setLoading] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [referenceLatitude, setReferenceLatitude] = useState(null);
  const [referenceLongitude, setReferenceLongitude] = useState(null)
  const [distance, setDistance] = useState(null);
  const [errors, setErrors] = useState({});
  const [nearestStore, setNearestStore] = useState(null);
  const [storeDistances, setStoreDistances] = useState([]);
  const handleGetLocation = async () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          try {
            const response = await getCurrentLocation(latitude, longitude);
            if (response && response.Results && response.Results.length > 0) {
              const place = response.Results[0].Place;
              const city = place.SubRegion || "N/A";
              const state = place.Region || "N/A";
              const country = place.Country || "N/A";
              const postalCode = place.PostalCode || "N/A";
              const fullAddress = place.Label || "";
              const trimmedAddress = fullAddress.split(",").slice(0, 3).join(",").trim();

              setFormData((prevFormData) => ({
                ...prevFormData,
                streetAddress: trimmedAddress,
                city: city,
                state: state,
                country: country,
                zipCode: postalCode,
              }));
            } else {
              message.error("Address not found");
            }
          } catch (error) {
            console.error("Error fetching address:", error);
            message.error("Could not fetch address");
          } finally {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              message.error("Location access denied. Please enable location services.");
              break;
            case error.POSITION_UNAVAILABLE:
              message.error("Location information is unavailable. Please try again.");
              break;
            case error.TIMEOUT:
              message.error("Location request timed out. Please try again.");
              break;
            default:
              message.error("An unknown error occurred while fetching location.");
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
     const validIndianStates = new Set([
      "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat",
      "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", 
      "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan",
      "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", 
      "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", 
      "Delhi", "Jammu and Kashmir", "Ladakh", "Lakshadweep", "Puducherry"
    ].map(state => state.toLowerCase()));


    let error = "";
    let processedValue = value;

    const validCountries = new Set(
      Object.values(countries).map((country) => country.name.toLowerCase())
    );

    if (name === "firstName" || name === "lastName") {
      processedValue = value.replace(/[0-9]/g, "");

      if (!/^[a-zA-Z\s]*$/.test(processedValue)) {
        error = "Name can only contain letters and spaces";
      } else {
        const lengthWithoutSpaces = processedValue.replace(/\s+/g, "").length;

        if (name === "firstName") {
          if (lengthWithoutSpaces < 3 || lengthWithoutSpaces > 25) {
            error = "Enter Valid Name";
          }
        } else if (name === "lastName") {
          if (lengthWithoutSpaces > 1 && lengthWithoutSpaces > 15) {
            error = "Last name must be between 1 and 15 letters";
          }

        }
      }
    } else if (name === "mobile") {
      processedValue = value.replace(/\D/g, "").slice(0, 10);

      if (!/^[5-9]{1}[0-9]{9}$/.test(processedValue)) {
        error = "Mobile number is invalid";
      }
    } else if (name === "city" || name === "state" || name === "country") {
      processedValue = value.trim();

      if (!/^[a-zA-Z\s]*$/.test(processedValue)) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} can only contain letters and spaces`;
      } else if (!processedValue) {
        error = `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`;
      } else if (name === "state" && !validIndianStates.has(processedValue.toLowerCase())) {
        error = "Please enter a valid Indian state.";
      } else if (name === "country" && !validCountries.has(processedValue.toLowerCase()) &&
        !["ind", "Ind", "IND"].includes(processedValue)) {
        error = "Please enter a valid country.";
      }
    } else if (name === "zipCode") {
      processedValue = value.replace(/[^0-9]/g, "").slice(0, 6);

      if (!processedValue.trim()) {
        error = "ZipCode is required.";
      } else if (processedValue.length !== 6) {
        error = "ZipCode must be exactly 6 digits.";
      }
    } else if (name === "addressLine1") {
      if (!value.trim()) {
        error = "Address Line 1 is required.";
      }
    } else if (name === "gst") {
      const gstPattern = /^[0-3][0-9][A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      processedValue = value.toUpperCase();
      if (processedValue && ((!gstPattern.test(processedValue) || (processedValue.length < 15 && processedValue.length> 0)))) {
        error = "Invalid GST number.";
      }
    }


    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : processedValue,
    });

    setErrors({
      ...errors,
      [name]: error,
    });
  };
  
  const fetchCoordinates = async () => {
    try {
      const response = await Instance.get("/api/v1/store-info/all", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      if (response.status === 200) {
        console.log("Store coordinates:", response.data);
        calculateStoreDistances(response.data); 
      }
    } catch (error) {
      console.error("Error fetching store data:", error);
      message.error(error?.response?.data?.message || "An error occurred");
    }
  };



  const calculateStoreDistances = (stores) => {
    if (!latitude || !longitude) return;

    const distances = stores.map((store) => {
      const distance = Math.ceil(calculateDistance(latitude, longitude, store.latitude, store.longitude) * 10) / 10;
      return { ...store, distance };
    });

    console.log("Distances to all stores:", distances);
    const nearest = distances.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr));
    setNearestStore(nearest);
    setDistance(nearest.distance);

    console.log(`Nearest Store: ${nearest.storeName}, Distance: ${nearest.distance} km`);
  };


  const handleAddressChange = async () => {
    const { streetAddress, address, city, state, country, zipCode } = formData;
    const fullAddress = `${streetAddress}, ${address}, ${city}, ${state}, ${country}, ${zipCode}`;

    try {
      const response = await getLocationFromAddress(fullAddress);
      if (response && response.Results && response.Results.length > 0) {
        const position = response.Results[0].Place.Geometry.Point;
        const addressLatitude = position[1];
        const addressLongitude = position[0];

        setLatitude(addressLatitude);
        setLongitude(addressLongitude);

        fetchCoordinates();

        setFormData((prevFormData) => ({
          ...prevFormData,
          latitude: latitude,
          longitude: longitude,
          distance: distance,
        }));

        console.log(`Latitude: ${latitude}, Longitude: ${longitude}, Distance: ${distance} km`);
      } else {
        message.error("Address not found");
      }
    } catch (error) {
      // console.error("Error fetching location based on address:", error);
      message.error("An error occurred while fetching location");
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => (degree * Math.PI) / 180;
    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  useEffect(() => {
    if (latitude && longitude) {
      fetchCoordinates();
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (
      formData.streetAddress &&
      formData.city &&
      formData.state &&
      formData.country &&
      formData.zipCode 
    ) {
      handleAddressChange();
    }
  }, [
    formData.streetAddress,
    formData.city,
    formData.state,
    formData.country,
    formData.zipCode,
  ]);

  const validateForm = () => {
    const validCountries = new Set(
      Object.values(countries).map((country) => country.name.toLowerCase())
    );
    const namePattern = /^[A-Za-z\s]*$/;
    const mobilePattern = /^[5-9][0-9]{9}$/;
    const gstPattern = /^[0-3][0-9][A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const validIndianStates = new Set([
      "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat",
      "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", 
      "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan",
      "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", 
      "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", 
      "Delhi", "Jammu and Kashmir", "Ladakh", "Lakshadweep", "Puducherry"
    ].map(state => state.toLowerCase()));

    let isValid = true;
    const newErrors = {};

    // Validate firstName
    if (!formData.firstName) {
      newErrors.firstName = "First name is required.";
      isValid = false;
    } else if (!namePattern.test(formData.firstName)) {
      newErrors.firstName = "First name can only contain letters and spaces.";
      isValid = false;
    } else {
      const lengthWithoutSpaces = formData.firstName.replace(/\s+/g, "").length;
      if (lengthWithoutSpaces < 3 || lengthWithoutSpaces > 15) {
        newErrors.firstName = "First name must be between 3 and 15 letters.";
        isValid = false;
      }
    }

    // Validate lastName
    if (formData.lastName) {
      const lengthWithoutSpaces = formData.lastName.replace(/\s+/g, "").length;
      if (!namePattern.test(formData.lastName)) {
        newErrors.lastName = "Last name can only contain letters and spaces.";
        isValid = false;
      } else if (lengthWithoutSpaces > 15) {
        newErrors.lastName = "Last name must be between 1 and 15 letters.";
        isValid = false;
      }
    }

    // Validate mobile
    if (!formData.mobile) {
      newErrors.mobile = "Mobile number is required.";
      isValid = false;
    } else if (!mobilePattern.test(formData.mobile)) {
      newErrors.mobile = "Mobile number must be 10 digits and cannot start with 0-4.";
      isValid = false;
    }

    // Validate city, state, and country
    ["city", "state", "country"].forEach((field) => {
      const value = formData[field]?.trim() || "";
      if (!value) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
        isValid = false;
      } else if (!namePattern.test(value)) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} can only contain letters and spaces.`;
        isValid = false;
      } else if (field === "state" && !validIndianStates.has(value.toLowerCase())) {
        newErrors.state = "Please enter a valid Indian state.";
        isValid = false;
      } else if (field === "country" && 
                 !validCountries.has(value.toLowerCase()) &&
                 !["ind", "Ind", "IND"].includes(value)) {
        newErrors.country = "Please enter a valid country.";
        isValid = false;
      }
    });

    // Validate zipCode
    if (!formData.zipCode) {
      newErrors.zipCode = "ZipCode is required.";
      isValid = false;
    } else if (!/^\d{6}$/.test(formData.zipCode)) {
      newErrors.zipCode = "ZipCode must be exactly 6 digits.";
      isValid = false;
    }

    // Validate addressLine1
    if (!formData.streetAddress?.trim()) {
      newErrors.streetAddress = "Address Line 1 is required.";
      isValid = false;
    }

    // Validate GST
    if (formData.gst) {
      const gst = formData.gst.toUpperCase();
      if (!gstPattern.test(gst) || gst.length !== 15) {
        newErrors.gst = "Invalid GST number. Please enter a valid 15-character GST number.";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {   
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (!latitude || !longitude) {
      const locationData = await handleAddressChange();

      if (locationData) {
        setLatitude(locationData.latitude);
        setLongitude(locationData.longitude);
        setDistance(locationData.distance);
      } else {
        message.error("Unable to retrieve location. Please verify the address and try again.");
        return;

      }
    }

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      streetAddress: formData.streetAddress,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      zipCode: formData.zipCode,
      mobile: formData.mobile,
      gstNumber:formData.gst,
      latitude: latitude,
      longitude: longitude,
      // distance: distance,      
    };
    console.log("Data to be sent:", data);
    try {
      setLoading(true)
      const response = await Instance.post("/api/v1/address/save", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });

      if (response.status === 200) {
        message.success("Address saved successfully!");

        setFormData({
          firstName: "",
          lastName: "",
          streetAddress: "",
          address: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
          mobile: "",
          gst:"",
          setAsDefaultBilling: false,
        });
        console.log("ADDED ADRS", response.data)
        setLatitude(null);
        setLongitude(null);
        setDistance(null);
        setLoading(false)
      }
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="container add-address-container addAddress-mobile mb-5">
      <div
        className="d-flex gap-2 align-content-center align-items-center current-location-icon-profile current-location-icon"
        onClick={handleGetLocation}
      >
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleGetLocation}
        >
          <path
            d="M24.0026 24.5C25.6626 25.0653 26.6693 25.8093 26.6693 26.624C26.6693 28.3973 21.8933 29.8333 16.0026 29.8333C10.1119 29.8333 5.33594 28.396 5.33594 26.624C5.33594 25.8093 6.3426 25.0653 8.0026 24.5M20.0026 13.1667C20.0026 14.2275 19.5812 15.2449 18.831 15.9951C18.0809 16.7452 17.0635 17.1667 16.0026 17.1667C14.9417 17.1667 13.9243 16.7452 13.1742 15.9951C12.424 15.2449 12.0026 14.2275 12.0026 13.1667C12.0026 12.1058 12.424 11.0884 13.1742 10.3382C13.9243 9.58809 14.9417 9.16666 16.0026 9.16666C17.0635 9.16666 18.0809 9.58809 18.831 10.3382C19.5812 11.0884 20.0026 12.1058 20.0026 13.1667Z"
            stroke="#fff"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 3.16666C21.412 3.16666 26 7.73733 26 13.2827C26 18.916 21.3373 22.8693 17.0307 25.5573C16.7171 25.7377 16.3617 25.8327 16 25.8327C15.6383 25.8327 15.2829 25.7377 14.9693 25.5573C10.6707 22.8427 6 18.936 6 13.2827C6 7.73733 10.588 3.16666 16 3.16666Z"
            stroke="#fff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div>
          <h5>Use current Location</h5>
        </div>
      </div>

      <h2 className="mt-4">Add Address</h2>

      <form className="row g-3" onSubmit={handleSubmit}>
        <Spin spinning={loading} size="large" tip="">
          <div className={`row g-3 ${loading ? 'blurred' : ''}`}>
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control add-address-form ${errors.firstName ? "invalid" : ""
                  }`}
                placeholder="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              {/* {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>} */}
              {errors.firstName && <p className="error">{errors.firstName}</p>}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control add-address-form ${errors.lastName ? "invalid" : ""
                  }`}
                placeholder="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <p className="error">{errors.lastName}</p>}
            </div>
            <div className="col-12">
              <input
                type="text"
                className={`form-control add-address-form ${errors.streetAddress ? "invalid" : ""
                  }`}
                id="inputAddress"
                placeholder="Address Line-1"
                name="streetAddress"
                value={formData.streetAddress}
                onChange={handleChange}
              />

              {errors.streetAddress && (
                <p className="error">{errors.streetAddress}</p>
              )}
            </div>
            <div className="col-12">
              <input
                type="text"
                className="form-control add-address-form"
                id="inputAddress2"
                placeholder="Enter Landmark"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              {/* {errors.addressLine1 && <p className="error">{errors.addressLine1}</p>} */}
            </div>
            <div className="col-md-6">
              {/* <p>{" "}</p> */}
              <input
                type="text"
                className={`form-control add-address-form ${errors.city ? "invalid" : ""
                  }`}
                id="inputCity"
                
                placeholder="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
              {errors.city && <p className="error">{errors.city}</p>}
            </div>
            <div className="col-md-6">
              {/* <p style={{marginBottom:"0px"}}>Optional</p> */}
              <input type="text"
                className={`form-control add-address-form ${errors.gst ? "invalid" : ""}`}
                id="gst"
                placeholder="Enter GST number (Optional)"
                name="gst"
                value={formData.gst}
                maxLength="15"
                onChange={handleChange}
              />
              {errors.gst && <p className="error">{errors.gst}</p>}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control add-address-form ${errors.city ? "invalid" : ""
                  }`}
                id="inputState"
                placeholder="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
              {errors.state && <p className="error">{errors.state}</p>}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control add-address-form ${errors.country ? "invalid" : ""
                  }`}
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Country"
              />
              {errors.country && <p className="error">{errors.country}</p>}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control add-address-form ${errors.zipCode ? "invalid" : ""
                  }`}
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                placeholder="Zip Code"
              />
              {errors.zipCode && <p className="error">{errors.zipCode}</p>}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                className={`form-control add-address-form ${errors.mobile ? "invalid" : ""
                  }`}
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                placeholder="Enter phone number"
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </div>

            <div className="d-flex gap-2">
              <button className="save-address-button faq-get-in-touch-button" type="submit">

                {loading ? (
                  <Spin size="small" />
                ) : (
                  "Save"
                )}
              </button>
              <button className="cancel-address-button" onClick={handleBack}>
                Cancel
              </button>
            </div>

          </div>
        </Spin>

      </form>

    </div>
  );
};

import React, { useState } from "react";
import { Modal, Input, Button,message } from "antd";
// import logo from "../../Assets/SKImages/white-bg-logo.png";
import VerificationModal from "./VerificationModal";
import Instance from "../../AxiosConfig";
import { Spin } from 'antd';

const ForgotPassword = ({ isModalOpen, handleCancel,onClose }) => {
  const [email, setEmail] = useState("");
  const [isVerificationModalVisible, setIsVerificationModalVisible] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleContinue = async () => {
    setEmailError("");
    if (!email) {
      setEmailError("Please enter your email address.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setLoading(true);
    try {
      const response = await Instance.post("/api/v1/forgotPassword/verifyMail", {
        email
      });
      console.log("Form submitted", response.data);

      if (response.status === 200 || response.status === 201) {
        setResponseMessage(response.data);
        setIsVerificationModalVisible(true);
        onClose();
      } else {
        message.error("An unexpected error occurred.");
      }

    } catch (error) {
      console.error("Error during form submission:", error);

      let errorMessage = "Login failed!";
      if (error.response) {
        console.error("Error response:", error.response);
        if (error.response.data && error.response.data.message) {
          const serverMessage = error.response.data.message;
          if (serverMessage.includes(":")) {
            errorMessage = serverMessage.split(":")[0];
          } else {
            errorMessage = serverMessage;
          }
        }
      }

      setEmailError(errorMessage);

    } finally {
      setLoading(false);
    }
    
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleContinue();
    }
  };
  

  return (
    <>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={500}
       
        
      >
        <div className="d-flex justify-content-center flex-column forgot-password-div" >
          {/* <div className="d-flex justify-content-center">
            <img src={logo} alt=".." style={{width:'300px', height:'auto'}}/>
          </div> */}
          <h2>Forgot Password</h2>
          <p className="forgotPassword-p-tag">
            Enter your email for the verification process, we will send a 6-digit code to your email.
          </p>
          <label htmlFor="forgot-password-email">Email <span style={{color:'red'}}>*</span></label>
          <Input
            id="forgot-password-email"
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          {emailError && <p style={{ color: "red" }}>{emailError}</p>}
          <Button key="submit" type="submit" className="continue-button mt-4" onClick={handleContinue} disabled={loading}>
            {loading ? <Spin /> : "Continue"}
          </Button>
        </div>
      </Modal>

      <VerificationModal
        isVisible={isVerificationModalVisible}
        onClose={() => setIsVerificationModalVisible(false)}
        responseMessage={responseMessage}
        email={email}
      />
    </>
  );
};

export default ForgotPassword;